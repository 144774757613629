import HeroTextBackground from '../../assets/svg/global/HeroTextBackground.svg';
import {Typography} from '@material-tailwind/react';

const Hero = () => {
  return (
    <div className="w-full mx-auto flex justify-center mb-24 px-4 lg:px-0">
      <div className="max-w-[1240px] w-full h-[170px] flex items-center justify-center rounded-[30px] overflow-hidden lg:h-[500px]"
           style={{
             backgroundImage: `url(${HeroTextBackground})`,
             backgroundRepeat: 'no-repeat',
             backgroundSize: 'cover',
           }}
      >
        <Typography
          variant="paragraph"
          className="text-center font-clash tracking-two font-semibold text-3xl leading-[100%] text-[#F6F7F8] lg:leading-[68px] lg:text-[68px]">
          Privacy Policy
        </Typography>
      </div>
    </div>
  );
};

export default Hero;
