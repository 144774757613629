import {useParams} from 'react-router-dom';
import {BlogDetailsData} from '../types/blog';
import {useEffect, useState} from 'react';
import {axiosGetRequest, axiosPutRequest} from '../lib/axios';

export const useBlogDetailsPage = () => {
  const { id } = useParams();
  const [blogData, setBlogData] = useState<BlogDetailsData | null>(null);
  const [scrollPercentage, setScrollPercentage] = useState<number>(0);
  const [scrollDisabled, setScrollDisabled] = useState<boolean>(false);

  useEffect(() => {
    const fetchBlogData = async () => {
      const response = await axiosGetRequest(`/blog/${id}`);
      if (response.success) {
        setBlogData(response.data as BlogDetailsData);

        window.scrollTo({top: 0, behavior: 'auto'});
      }
    };

    const handleScroll = async () => {
      if (scrollDisabled) {
        return;
      }

      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;

      const currentScrollPercentage = (scrollTop / (documentHeight - windowHeight)) * 100;
      setScrollPercentage(currentScrollPercentage);

      // Check if the user has reached 50%
      if (currentScrollPercentage >= 25 && currentScrollPercentage <= 30) {
        setScrollDisabled(true);
        if (!scrollDisabled && currentScrollPercentage >= 25) {
          await axiosPutRequest(`/blog/read/${id}`);
        }
      }
    };

    fetchBlogData();

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [id, scrollDisabled]);

  return {
    id,
    blogData,
    scrollPercentage,
  };
};