import { Fragment } from 'react';
import OrnaMapLogo from '../../assets/img/global/logo-white-tint.svg';
import {Button, Typography} from '@material-tailwind/react';
import AppleLogo from '../../assets/svg/homepage/Apple-Logo.svg';
import PlayStoreLogo from '../../assets/svg/homepage/PlayStore-Logo.svg';
import {Link} from 'react-router-dom';
import {FaInstagram, FaLinkedin, FaXTwitter} from 'react-icons/fa6';
import {AiFillFacebook, AiFillTikTok} from 'react-icons/ai';

const Footer = () => {
  return (
    <Fragment>
      <footer className="w-full bg-[#282B59] h-[1000px] overflow-hidden flex flex-col justify-center pt-16 items-center px-4 lg:px-0 lg:h-[792px]">
        <div className="w-full h-full max-w-[1240px] flex flex-col items-baseline justify-start gap-8 lg:gap-16">
          <div className="w-full flex flex-col justify-between lg:gap-24 lg:flex-row">
            {/*OrnaMap*/}
            <div className="w-full flex flex-col gap-3">
              <div className="flex gap-2">
                <div className="w-[30.5px] h-[31.3px]">
                  <img src={OrnaMapLogo} alt={OrnaMapLogo}/>
                </div>
                <Typography
                  className="font-clash text-2xl leading-[29.2px] tracking-four text-[#EAEAEE] font-semibold">OrnaMap</Typography>
              </div>

              <Typography
                className="font-clash font-normal text-sm leading-[17.22px] tracking-two text-[#EAEAEE] max-w-[153px]">Never
                Miss a Beat. Own Every Commute.</Typography>
            </div>

            {/*Download Mobile*/}
            <div className="flex flex-col gap-5 my-8 lg:hidden">
              {/*IOS*/}
              <Link to="https://apps.apple.com/ng/app/ornamap/id6450780439" target="_blank" className="max-w-[242px] font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">
                <Button
                  size="sm"
                  className="bg-transparent gap-2 rounded-full justify-center py-4 px-[10px] text-white normal-case flex items-center w-[200px] border hover:cursor-pointer border-[#3E406A] hover:bg-[#3E406A] hover:border-none"
                >
                  <img src={AppleLogo} alt={AppleLogo} height={20} className=""/>
                  <span>Download for IOS</span>
                </Button>
              </Link>

              {/*Android*/}
              <Link to="https://play.google.com/store/apps/details?id=com.ornamap.android" target="_blank" className="max-w-[242px] font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">
                <Button
                  size="sm"
                  className="bg-transparent gap-2 rounded-full justify-center py-4 px-[10px] text-white normal-case flex items-center w-[200px] border hover:cursor-pointer border-[#3E406A] hover:bg-[#3E406A] hover:border-none"
                >
                  <img src={PlayStoreLogo} alt={PlayStoreLogo} height={20} className=""/>
                  <span>Download for Android</span>
                </Button>
              </Link>
            </div>

            {/*Mobile*/}
            <div className='w-full grid grid-cols-2 my-8 lg:hidden'>
              {/*Company*/}
              <div className="w-full flex flex-col justify-start items-baseline">
                <Typography
                  className="font-clash font-medium text-base leading tracking-two text-[#EAEAEE]">Company</Typography>

                <div className="flex flex-col mt-6 gap-4">
                  <a href="/about" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">About Us</a>

                  <a href="https://blog.ornamap.com/" target="_blank" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]" rel="noreferrer">Blog</a>

                  <a href="/faq" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">FAQs</a>
                </div>
              </div>

              {/*Legal*/}
              <div className="w-full flex flex-col justify-start items-baseline">
                <Typography
                  className="font-clash font-medium text-base leading tracking-two text-[#EAEAEE]">Legal</Typography>

                <div className="flex flex-col mt-6 gap-4">
                  <a href="/privacy-policy" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">Privacy Policy</a>

                  <a href="/terms" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">Terms & Condition</a>
                </div>
              </div>
            </div>

            {/*Company*/}
            <div className="w-full hidden flex-col justify-start items-baseline lg:flex">
              <Typography
                className="font-clash font-medium text-base leading tracking-two text-[#EAEAEE]">Company</Typography>

              <div className="flex flex-col mt-6 gap-4">
                <a href="/about" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">About Us</a>

                <a href="https://blog.ornamap.com/" target="_blank" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]" rel="noreferrer">Blog</a>

                <a href="/faq" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">FAQs</a>
              </div>
            </div>

            {/*Legal*/}
            <div className="w-full hidden flex-col justify-start items-baseline lg:flex">
              <Typography
                className="font-clash font-medium text-base leading tracking-two text-[#EAEAEE]">Legal</Typography>

              <div className="flex flex-col mt-6 gap-4">
                <a href="/privacy-policy" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">Privacy Policy</a>

                <a href="/terms" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">Terms & Condition</a>
              </div>
            </div>

            {/*Contact Us*/}
            <div className="w-full flex flex-col justify-start items-baseline">
              <Typography
                className="font-clash font-medium text-base leading tracking-two text-[#EAEAEE]">Contact Us</Typography>

              <div className="flex flex-col mt-6 gap-4">
                <Link to="tel:+2347051334957" target="_blank" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">+234 705 133 4957</Link>

                <Link to="mailto:support@ornamap.com" target="_blank" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">support@ornamap.com</Link>

                <Link to="https://www.google.com/maps/place/3+Folorunsho+Kazeem+Cl,+Ikosi+Ketu,+Lagos+105102,+Lagos" target="_blank" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">3, Folorusho Kazeem Close, Lagos Nigeria</Link>
              </div>
            </div>

            {/*Download Web*/}
            <div className="flex-col gap-5 hidden lg:flex">
              {/*IOS*/}
              <Link to="https://apps.apple.com/ng/app/ornamap/id6450780439" target="_blank" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">
                <Button
                  size="sm"
                  className="bg-transparent gap-2 rounded-full justify-center py-4 px-[41.5px] text-white normal-case flex lg:items-center w-[242px] border hover:cursor-pointer border-[#3E406A] hover:bg-[#3E406A] hover:border-none"
                >
                  <img src={AppleLogo} alt={AppleLogo} height={20} className=""/>
                  <span>Download for IOS</span>
                </Button>
              </Link>

              {/*Android*/}
              <Link to="https://play.google.com/store/apps/details?id=com.ornamap.android" target="_blank" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">
                <Button
                  size="sm"
                  className="bg-transparent gap-2 rounded-full justify-center py-4 px-[41.5px] text-white normal-case flex lg:items-center w-[242px] border hover:cursor-pointer border-[#3E406A] hover:bg-[#3E406A] hover:border-none"
                >
                  <img src={PlayStoreLogo} alt={PlayStoreLogo} height={20} className=""/>
                  <span>Download for Android</span>
                </Button>
              </Link>
            </div>
          </div>

          {/*Divider Line*/}
          <div className="bg-[#3E406A] w-full h-[1px]"/>

          <div className="w-full flex items-baseline gap-4 justify-between flex-col-reverse lg:items-center lg:flex-row">
            <Typography className="text-[#53557A] font-normal font-clash leading-[19.68px] tracking-two text-base">©2024 OrnaMap. All rights reserved.</Typography>

            {/*Socials*/}
            <div className="w-full grid gap-4 grid-cols-3 lg:flex lg:justify-between lg:gap-12 lg:w-7/12">
              {/*Twitter*/}
              <Link to="https://x.com/ornamapapp" target="_blank" className="flex gap-2 items-center text-[#53557A] hover:!text-white">
                <FaXTwitter className="h-[25px] w-[25px]" />

                <Typography className="font-clash font-medium text-base leading-[16px] tracking-two">X
                  (Twitter)</Typography>
              </Link>

              {/*Tiktok*/}
              <Link to="https://www.tiktok.com/@ornamap" target="_blank" className="flex gap-2 items-center text-[#53557A] hover:!text-white">
                <AiFillTikTok className="h-[30px] w-[30px]" />

                <Typography
                  className="font-clash font-medium text-base leading-[16px] tracking-two">TikTok</Typography>
              </Link>

              {/*Facebook*/}
              <Link to="https://www.facebook.com/ornamap" target="_blank" className="flex gap-2 items-center text-[#53557A] hover:!text-white">
                <AiFillFacebook className="h-[30px] w-[30px]" />

                <Typography
                  className="font-clash font-medium text-base leading-[16px] tracking-two">Facebook</Typography>
              </Link>

              {/*LinkedIn*/}
              <Link to="https://www.linkedin.com/company/ornamap" target="_blank" className="flex gap-2 items-center text-[#53557A] hover:!text-white">
                <FaLinkedin className="h-[30px] w-[30px]" />
                <Typography
                  className="font-clash font-medium text-base leading-[16px] tracking-two">LinkedIn</Typography>
              </Link>

              {/*Instagram*/}
              <Link to="https://www.instagram.com/ornamap" target="_blank" className="flex gap-2 items-center text-[#53557A] hover:!text-white">
                <FaInstagram className="h-[30px] w-[30px]" />

                <Typography
                  className="font-clash font-medium text-base leading-[16px] tracking-two">Instagram</Typography>
              </Link>
            </div>
          </div>
        </div>

        <div className="w-full items-center justify-center lg:px-10">
          <Typography className="text-[#3E406A] tracking-two font-semibold text-[94.02px] leading-[100%] font-clash w-full text-center lg:text-[300px] lg:leading-[408.36px]">OrnaMap</Typography>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;