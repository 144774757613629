import Hero from '../components/terms/hero';
import Info from '../components/terms/info';
import YourExperience from '../components/home/YourExperience';
import Layout from '../Layout';


const TermsNew = () => {
  return (
    <Layout>
      <Hero/>

      <Info/>

      <YourExperience/>
    </Layout>
  );
};

export default TermsNew;
