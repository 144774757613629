import {Fragment} from 'react';
import BackgroundShortImage from '../assets/img/global/bg-hero-short-section.jpg';
import HeaderWithBgImg from '../components/global/HeaderWithBgImg';
import Text from '../components/global/Text';
import ErrorAirBalloon from '../assets/img/pageNotFound/Error Air Balloon.png';

const PageNotFound = () => {
    return (
        <Fragment>
            <HeaderWithBgImg bgImg={BackgroundShortImage}/>

            <main id={'page-not-found'}>
                <div className={'flex flex-col items-center justify-center  w-full min-h-[500px] h-full lg:mt-[100px] px-4 py-10 lg:px-[100px] bg-[#EAEAEE] lg:py-20 h-screen'}>
                    <div className={'max-w-screen-xl flex w-full h-full grid-cols-1 gap-4 lg:grid lg:grid-cols-2 gaps-2'}>
                        <div className={'w-full h-full flex flex-col items-center justify-center lg:items-start'}>
                            <Text
                                weight={900}
                                className={'text-[55px] text-[#282859] leading-normal'}
                                text={'404'}
                            />

                            <Text
                                weight={800}
                                className={'text-[25px] text-[#282859] leading-normal'}
                                text={'Ooops!'}
                            />

                            <Text
                                weight={800}
                                className={'text-[25px] text-[#282859] leading-normal'}
                                text={'Page not found'}
                            />

                            <Text
                                weight={400}
                                className={'text-[18px] text-[#282859] max-w-[435px] leading-normal mt-3 text-center lg:text-justify'}
                                text={'It seems you\'ve reached a page that doesn\'t exist. Don\'t worry, we\'re here to help you get back on track.'}
                            />

                            <a href={'/'} className={'py-[15px] px-[30px] bg-[#282B59] rounded-xl text-white text-[18px] font-bold mt-5'}>
                                Go to Home
                            </a>
                        </div>

                        <div className={'w-full h-full flex-col hidden items-center justify-center lg:flex'}>
                            <img src={ErrorAirBalloon} alt={ErrorAirBalloon}/>
                        </div>
                    </div>
                </div>
            </main>
        </Fragment>
    );
};

export default PageNotFound;