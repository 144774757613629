import {Fragment, ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {Typography} from '@material-tailwind/react';
import {Calendar} from 'lucide-react';

const Info = () => {
  const scrollToSection = (e: any, id: string) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      window.history.pushState(null, '', `#${id}`);
    }
  };


  const SidebarLink = ({ href, children }: { href: string, children: ReactNode}) => (
    <a
      href={`#${href}`}
      onClick={(e) => scrollToSection(e, href)}
      className="font-clash font-medium text-[16px] leading-[16px] tracking-two text-[#53557A] hover:text-[#282B59] transition-colors"
    >
      {children}
    </a>
  );

  return (
    <Fragment>
      <div className="w-full mx-auto flex justify-center mt-24 px-4 lg:px-0">
        <div className="w-full max-w-[1240px] grid grid-cols-1 items-center gap-24 lg:grid-cols-[30%_70%]">
          <div className="h-full w-full hidden items-baseline sticky lg:flex">
            <div className="flex w-full flex-col gap-8 pl-4 py-6 pr-7 bg-[#EAEAEE] sticky top-0">
              <SidebarLink href="#app-usage">App Usage</SidebarLink>

              <SidebarLink href="#privacy">Privacy</SidebarLink>

              <SidebarLink href="#refund-policy">Refund Policy</SidebarLink>

              <SidebarLink href="#contact-info">Contact Information</SidebarLink>

              <SidebarLink href="#important-notes">Important Notes</SidebarLink>

              <SidebarLink href="#intellectual-property">Intellectual Property</SidebarLink>

              <SidebarLink href="#limitation-of-liability">Limitation of Liability</SidebarLink>

              <SidebarLink href="#third-party-links">Third-Party Links</SidebarLink>

              <SidebarLink href="#governing-law">Governing Law and Jurisdiction</SidebarLink>

              <SidebarLink href="#money-laundering">Anti-Money Laundering Policy</SidebarLink>

              <SidebarLink href="#commitment">Our Commitment</SidebarLink>
            </div>
          </div>

          <div className="w-full h-full flex flex-col items-baseline gap-8">
            {/*Terms of Use*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#terms-of-use">
              <Typography variant="h2"
                          className="font-clash font-semibold leading-[40px] tracking-two text-[#080912] text-[40px]">
                Terms of Use
              </Typography>

              <div className="flex gap-1 items-center mb-3">
                <Calendar className="h-4 w-4 text-[#53557A] lg:h-6 lg:w-6"/>

                <Typography
                  variant="h6"
                  className="font-clash font-medium tracking-two text-base leading-4 text-[#53557A]"
                >
                  Last modified: April 21, 2022
                </Typography>
              </div>

              <Typography variant="paragraph"
                          className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]">
                At OrnaMap, we are committed to providing a seamless and enjoyable experience for all our users. This
                includes ensuring that your transactions are processed smoothly and securely. However, we understand
                that technical issues may occasionally arise. Our refund policy is designed to address these situations
                and ensure your satisfaction.
              </Typography>
            </div>

            {/*App Usage*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#app-usage">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                App Usage
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                (i.) Purpose: OrnaMap is designed to provide information and services related to train and ferry
                transportation, aiming to enhance your travel experience.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                (ii.) User Responsibilities: You are responsible for using the App in accordance with applicable laws
                and regulations. Any unauthorised use or violation of these Terms may result in the termination of your
                access to the App.
              </Typography>

            </div>

            {/*PrivacyNew*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#privacy">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                Privacy
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                (i.) Data Collection: We may collect certain information from you as outlined in our Privacy Policy. By using the App, you consent to the collection, use, and disclosure of your personal information as described in the Privacy Policy.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                (ii.) Data Security: We prioritise the security of your data and employ industry-standard measures to protect your information. However, please be aware that no method of data transmission over the internet or electronic storage is entirely secure.
              </Typography>

            </div>

            {/*Refund Policy*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#refund-policy">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                Refund Policy
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                At OrnaMap, we are committed to providing a seamless and enjoyable experience for all our users. This includes ensuring that your transactions are processed smoothly and securely. However, we understand that technical issues may occasionally arise. Our refund policy is designed to address these situations and ensure your satisfaction.
              </Typography>

              {/*Refund eligibility*/}
              <Typography
                variant="h6"
                className="font-clash font-medium tracking-two text-base leading-6 text-[#2D2D2D]"
                >
                Refund Eligibility
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                (i.) ⁠Failed Transactions: If your transaction fails and the payment is deducted from your account, you are eligible for a refund.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                (ii.) Duplicate Transactions: If you are charged multiple times for the same transaction, we will refund the duplicate charges.
              </Typography>

              {/*Refund Process*/}
              <Typography
                variant="h6"
                className="font-clash font-medium tracking-two text-base leading-6 text-[#2D2D2D]"
              >
                Refund Process
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                To initiate the refund process, please contact our customer support team within 30 days of the transaction date. You will need to provide details of the transaction, including the date, time, and amount deducted, as well as any transaction reference numbers or receipts you have received. Our team will then investigate the issue and process your refund as quickly as possible.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                We aim to resolve all refund requests within 7 to 10 business days. However, please be aware that the time it takes for the refund to appear in your account may vary depending on your bank or payment provider. We will keep you informed throughout the process and provide updates on the status of your refund.
              </Typography>

            </div>

            {/*Contact Information*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#contact-info">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                Contact Information
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                For any refund-related queries or to report a transaction issue, please contact our customer support team at <Link to="mailto:support@ornamap.com" target="_blank" className="font-clash hover:text-[#282B59]">support@ornamap.com</Link>. Our customer support is available Monday to Friday, from 9:00 AM to 5:00 PM (WAT).
              </Typography>
            </div>

            {/*Important Notes*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#important-notes">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                Important Notes
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                Refunds are only applicable for failed transactions where money has been deducted from your account. OrnaMap reserves the right to amend this refund policy at any time. Any changes will be communicated through our app and website.
                <br/>
                Thank you for choosing OrnaMap. We appreciate your understanding and cooperation in ensuring a smooth refund process.
              </Typography>
            </div>

            {/*Intellectual Property*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#intellectual-property">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                Intellectual Property
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                (i.) Ownership: The App and its content, including but not limited to text, graphics, logos, and images, are protected by intellectual property laws and are the exclusive property of OrnaMap Ltd. All rights not expressly granted to you under these Terms are reserved by OrnaMap Ltd.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                (ii.) Limited License: We grant you a limited, non-exclusive, non-transferable license to use the App for personal, non-commercial purposes. You may not modify, reproduce, distribute, create derivative works, or reverse engineer any part of the App.
              </Typography>

            </div>

            {/*Limitation of Liability*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#limitation-of-liability">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                Limitation of Liability
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                (i.) Disclaimer: The information and services provided by OrnaMap are for general informational purposes only. We make no warranties or representations about the accuracy, reliability, completeness, or timeliness of the content.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                (ii.) Indemnification: You agree to indemnify, defend, and hold OrnaMap Ltd. harmless from any claims, liabilities, damages, losses, or expenses arising out of or in connection with your use of the App.
              </Typography>

            </div>

            {/*Third-Party Links*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#third-party-links">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                third-party-links
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                Our app may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these websites.
              </Typography>
            </div>

            {/*Governing Law and Jurisdiction*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#governing-law">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                Governing Law and Jurisdiction
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                (i.) Governing Law: These Terms and any disputes arising from or relating to them shall be governed by and construed in accordance with the laws of Nigeria, Lagos state.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                (ii.) Dispute Resolution: Any disputes or claims arising from or related to these Terms or your use of the App shall be subject to the exclusive jurisdiction of the courts in Nigeria, Lagos state.
              </Typography>

            </div>

            {/*Anti-Money Laundering Policy*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#money-laundering">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                Anti-Money Laundering Policy
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                We, at OrnaMap Technologies Limited are committed to providing a safe and secure platform for our users
                to plan their trips and fund their cowry cards in Nigeria. This Anti-Money Laundering (AML) Policy
                outlines the measures we take to prevent our app from being used for illegal activities such as money
                laundering
              </Typography>

              {/*1. Customer Due Diligence (CDD)*/}
              <div className="flex flex-col gap-1">
                <Typography
                  variant="h4"
                  className="font-clash font-medium tracking-two text-base leading-6 text-[#2D2D2D]"
                >
                  1. Customer Due Diligence (CDD)
                </Typography>

                <Typography
                  variant="paragraph"
                  className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
                >
                  (i.) Verification: We require all users to register for an account with OrnaMap Technologies Limited.
                  During registration, we collect basic user information such as name, phone number, and email address.
                </Typography>

                <Typography
                  variant="paragraph"
                  className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
                >
                  (ii.) Cowry Wallet Verification: For users paying into their cowry wallets with OrnaMap Technologies
                  Limited, we will implement procedures to verify the ownership and legitimacy of the cowry wallet. This
                  may involve collaborating with the cowry wallet provider to obtain relevant information.
                </Typography>

                <Typography
                  variant="paragraph"
                  className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
                >
                  (iii.) Suspicious Activity Monitoring: We monitor user activity for suspicious patterns that could
                  indicate money laundering, such as anonymous payment methods or frequent bookings with different
                  accounts originating from the same location.
                </Typography>
              </div>

              {/*2. Customer Due Diligence (CDD)*/}
              <div className="flex flex-col">
                <Typography
                  variant="h4"
                  className="font-clash font-medium tracking-two text-base leading-6 text-[#2D2D2D]"
                >
                  2. Customer Due Diligence (CDD)
                </Typography>

                <Typography
                  variant="paragraph"
                  className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
                >
                  We set limits on the amount a user can fund their wallets with through the app in a single
                  transaction. This helps us identify and flag unusually large transactions that could be suspicious.
                </Typography>
              </div>

              {/*3. KYC/AML for Cowry Wallets*/}
              <div className="flex flex-col">
                <Typography
                  variant="h4"
                  className="font-clash font-medium tracking-two text-base leading-6 text-[#2D2D2D]"
                >
                  3. KYC/AML for Cowry Wallets
                </Typography>

                <Typography
                  variant="paragraph"
                  className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
                >
                  We will rely on Cowry to perform KYC (Know Your Customer) and AML checks on their users. This ensures
                  that users linking their Cowry wallets to OrnaMap have already undergone identity verification.
                </Typography>
              </div>

              {/*4. Reporting*/}
              <div className="flex flex-col">
                <Typography
                  variant="h4"
                  className="font-clash font-medium tracking-two text-base leading-6 text-[#2D2D2D]"
                >
                  4. Reporting
                </Typography>

                <Typography
                  variant="paragraph"
                  className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
                >
                  We are committed to reporting any suspicious activity to the relevant authorities. We will maintain detailed records of all transactions conducted through the OrnaMap platform for a period of at least two (2) years.
                </Typography>
              </div>
            </div>

            {/*Our Commitment*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#commitment">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                Our Commitment
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                We are committed to upholding the highest standards of AML compliance. We regularly review and update our policies and procedures to stay ahead of evolving money laundering techniques. We also work closely with law enforcement agencies to combat financial crime.
                <br/>
                By using OrnaMap, you agree to comply with this AML Policy. We reserve the right to suspend or terminate your account if we suspect you are using our platform for illegal activities.
                OrnaMap is committed to providing a safe and secure platform for all our users.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                These Terms and Conditions create the entire agreement between you and OrnaMap. regarding your use of the App. If you have any questions or concerns about these Terms, please contact us at <Link to="mailto:support@ornamap.com" target="_blank" className="font-clash hover:text-[#282B59]">support@ornamap.com</Link>.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Info;