import {Fragment, useEffect} from 'react';
import BackgroundImage from '../assets/img/homepage/bg-hero-section.jpg';
import HeaderWithBgImg from '../components/global/HeaderWithBgImg';
import BackgroundShortImage from '../assets/img/global/bg-hero-short-section.jpg';
import Discover from '../components/home/page_components/Discover';
import ServiceTerms from '../components/home/page_components/ServiceTerms';
import GetStated from '../components/download/GetStarted';
import DownloadPlaystore from '../components/home/page_components/DownloadPlaystore';
import PopularRoutes from '../components/home/page_components/PopularRoutes';
import FerryArrival from '../components/home/page_components/FerryArrival';
import GotQuestions from '../components/home/page_components/GotQuestions';
import DiscoverOrna from '../components/home/DiscoverOrna';
import Footer from '../components/global/Footer';

const DownloadPage = () => {
  useEffect(() => {
    const divElement = document.getElementById('download-playstore');
      divElement?.scrollIntoView({ behavior: 'smooth' });
  }, []);
    const discoverSection = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover', // This will make the image cover the section without repeating
  };

    return (
    <Fragment>

      <HeaderWithBgImg bgImg={BackgroundShortImage}/>

      <main className={'scroll-smooth'}>
        <section id="discover" style={discoverSection} className="flex min-h-[700px] items-center justify-center px-0 py-10 lg:px-4 lg:px-10 lg:pb-20 lg:pt-[155px] lg:min-h-[900px]">
          <Discover/>
        </section>

        <section id="service-terms" className="flex items-center justify-center px-4 py-10 lg:px-10 lg:py-20">
          <ServiceTerms/>
        </section>

        <section id="get-started" className="flex items-center justify-center bg-white px-4 py-10 lg:px-10 lg:py-20">
          <GetStated/>
        </section>

        <section id="download-playstore" className="flex items-center justify-center bg-white px-4 py-10 lg:px-10 lg:py-20">
          <DownloadPlaystore/>
        </section>

        <section id="popular-routes" className="flex items-center justify-center py-10 bg-white px-4 py-10 lg:px-10 lg:py-20">
          <PopularRoutes/>
        </section>

        <section id="ferry-arrival" className="flex items-center justify-center py-10 bg-white px-4 py-10 lg:px-[100px] lg:py-20">
          <FerryArrival/>
        </section>

        <section id="got-options" className="flex items-center justify-center py-10 bg-white px-4 py-10 lg:px-10 lg:py-20">
          <GotQuestions/>
        </section>

        <section
          id="discover-orna"
          className={`flex items-center justify-center flex-col pt-5 px-4 min-h-[260px] bg-cover lg:bg-auto lg:px-0
           lg:px-10 bg-[url(https://res.cloudinary.com/zalajobi/image/upload/v1688676997/tupxfdqlqubfcjmehnya.svg)] lg:min-h-[500px]`}
        >
          <DiscoverOrna/>
        </section>
      </main>


      <Footer/>
    </Fragment>
  );
};

export default DownloadPage;