import {Fragment} from 'react';
import {Card, Typography} from '@material-tailwind/react';
import GetStartedBG from '../../assets/svg/homepage/GetStarted.svg';

const GetStarted = () => {
  return (
    <Fragment>
      <div className="w-full mx-auto flex justify-center px-4 lg:px-0">
        <div className="w-full flex flex-col gap-4 items-center justify-center max-w-[1240px]">
          <Typography variant="h2" className="text-[#080912] tracking-two leading-[100%] font-semibold text-center font-clash lg:text-[40px]">
            How to get started
          </Typography>

          <Card
            className={'rounded-[30px] grid grid-cols-1 w-full bg-[#EAEAEE] my-8 bg-no-repeat bg-center p-10 shadow-none gap-8 lg:grid-cols-3 lg:min-h-[650px]'}
          >
            <div className="w-full h-full gap-2 flex flex-col lg:justify-center lg:items-start lg:min-h-[650px]">
              <Typography variant="h5"
                          className="w-full text-4xl font-semibold text-[#080912] font-clash text-center lg:text-left">Search</Typography>

              <Typography
                variant="h5"
                className="w-full text-lg tracking-two font-normal text-[#53557A] font-clash max-w-[340px] flex-wrap text-center lg:text-left"
              >
                Enter your destination in the {'\''}Where to?{'\''} field. Your current location will be selected by default, but
                you can edit it if needed.
              </Typography>
            </div>

            <div className='w-full h-full'>
              <img src={GetStartedBG} alt={GetStartedBG} width='100%' height='100%'/>
            </div>
          </Card>
        </div>
      </div>
    </Fragment>
  );
};

export default GetStarted;
