import {useSearchParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import {payloadTypes} from '../types/index.types';

export const usePreviewsPage = () => {
  const [searchParams] = useSearchParams();
  const payload = searchParams.get('payload');
  const [jsonPayload, setJsonPayload] = useState<payloadTypes | null>(null);

  useEffect(() => {
    if (!payload) {
      toast.error('No payload found');
    }

    setJsonPayload(JSON.parse(payload as string) as payloadTypes);
  }, [payload]);

  return {
    payload,
    jsonPayload
  };
};