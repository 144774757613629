import {Fragment} from 'react';
import {Typography} from '@material-tailwind/react';
import BonusRightRing from '../../assets/svg/homepage/Bonus-Right-Ring.svg';
import BonusBottomRing from '../../assets/svg/homepage/Bonus-Bottom-Ring.svg';
import BonusView from '../../assets/img/homepage/BonusView.png';

const Bonus = () => {
  return (
    <Fragment>
      <div className="w-full mx-auto flex justify-center px-4 lg:px-0">
        <div className="w-full max-w-[1240px] overflow-hidden gap-10 min-h-[440px] rounded-[30px] h-[650px] flex items-start justify-start my-20 bg-[#E7F6EA] border border-[#12A6284D] flex-col lg:flex-row lg:h-[500px]lg:gap-24 lg:h-[510px]">
          <div className="w-full flex h-full flex-col lg:flex-row lg:w-7/12">
            <div className="h-[133px] absolute left-[15px] lg:top-0 lg:left-0 lg:relative lg:w-[265px]">
              <img src={BonusRightRing} alt={BonusRightRing} className="w-full h-full"/>
            </div>

            <div className="h-full gap-4 flex flex-col items-center justify-center mt-10 px-3 lg:mt-0 lg:px-0 lg:w-full">
              <Typography className="font-clash font-semibold tracking-two leading-[100%] text-[32px] w-[338px] text-[#080912] lg:w-[438px] lg:text-[40px] lg:leading-[40px]" variant="h2">
                Earn up to ₦500 as a welcome sign-up bonus bonus!
              </Typography>

              <Typography className="text-[#53557A] font-normal font-clash tracking-two text-justify w-[338px] text-lg lg:w-[438px] lg:text-2xl lg:leading-[29.52px]">
                Plus, enjoy instant cashback on your Cowry card every time you top up. It’s fast, easy, and the rewards keep coming.
              </Typography>
            </div>

            <div className="h-[133px] hidden top-[365px] -left-[70px] lg:relative lg:block lg:left-[-60px] lg:top-[408px]">
              <img src={BonusBottomRing} alt={BonusBottomRing} className="w-full h-full"/>
            </div>
          </div>

          <div className='flex items-center justify-center w-full'>
            <div className="h-[590x] w-[338px] px-8 lg:h-full lg:w-[400px] lg:mt-10 lg:mr-[68px] lg:px-0">
              <img src={BonusView} alt={BonusView}/>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Bonus;
