import { Card, CardBody, CardHeader, Typography } from '@material-tailwind/react';
import {Fragment} from 'react';
import {useNavigate} from 'react-router-dom';


interface BlogItemProps {
    id: string | number;
    blogImgURL: string;
    bodyHeader: string;
    bodyText: string;
    date: string;
    writer: string;
    bodyClassName?: string;
    showFooter?: boolean;
    cardClassName?: string;
    headerClassName?: string;
    bodyHeaderClassName?: string;
    bodyTextClassName?: string;
    footerURL?: string;
}
const BlogItem = ({id, blogImgURL, bodyHeader, bodyText, date, writer, bodyClassName='', showFooter=false, cardClassName='', headerClassName='', bodyHeaderClassName='', bodyTextClassName=''}:BlogItemProps) => {
    const navigate = useNavigate();

    return (
        <Fragment>
            <Card className={`w-[24rem] overflow-hidden hover:cursor-pointer ${cardClassName}`} onClick={() => navigate(`/blog/${id}`)}>
                <CardHeader
                    floated={true}
                    shadow={false}
                    color="transparent"
                    className={`m-0 rounded-none ${headerClassName}`}
                >
                    <img
                        src={blogImgURL}
                        alt={blogImgURL}
                        className={'w-full h-full bg-gray-400 rounded-t-md bg-clip-padding backdrop-filter backdrop-blur-lg bg-opacity-40 border border-gray-100 object-cover'}
                        loading={'eager'}
                    />
                </CardHeader>
                <CardBody className={bodyClassName}>
                    <Typography variant="h4" color="blue-gray" className={`font-nunito ${bodyHeaderClassName}`}>
                        {bodyHeader}
                    </Typography>
                    <Typography variant="lead" color="gray" className={`mt-3 font-normal font-nunito ${bodyTextClassName}`}>
                        {bodyText}
                    </Typography>
                </CardBody>
                {showFooter && (
                    <div className="p-6 flex items-center justify-start text-primary w-full hover:cursor-pointer py-4">
                        <Typography className="font-bold font-nunito">{writer} </Typography>

                        <div className={'w-[1px] h-full max-h-[25px] bg-[#9495AC] mx-2'}></div>

                        <Typography className="font-normal font-nunito"> {date}</Typography>
                    </div>
                )}
            </Card>
        </Fragment>
    );
};

export default BlogItem;