import {Fragment} from 'react';
import {Card, Typography} from '@material-tailwind/react';
import LagosBlueLine from '../../assets/img/homepage/Lagos-Blue-Line.png';
import LagosRedLine from '../../assets/img/homepage/Lagos-Red-Line.png';
import LagosFerry from '../../assets/img/homepage/Lagos-Ferry.png';
import LagosBRTNetwork from '../../assets/img/homepage/Lagos-BRT-Network.png';
import NRCLagosIbadan from '../../assets/img/homepage/NRC-Lagos-Ibadan.png';
import OpenDataTrustedPartner from '../../assets/svg/homepage/OpenDataTrustedPartner.svg';
import CowryTrustedPartner from '../../assets/svg/homepage/CowryTrustedPartner.svg';

const OurSolutions = () => {
  return (
    <Fragment>
      <div className="w-full mx-auto flex justify-center px-4 lg:px-0">
        <div className="w-full flex flex-col items-center gap-4 mt-20 max-w-[1240px]">
          {/*Trusted Partners*/}
          <div className='w-full flex items-center justify-center lg:hidden'>
            <div className='w-[75%] flex flex-col item-center justify-center gap-4'>
              <Typography
                variant="h3"
                color="blue-gray"
                className="w-full font-semibold text-[#53557A] text-base text-center leading-[100%] tracking-two font-clash"
              >
                Trusted Partners
              </Typography>

              <div className='w-full flex gap-4'>
                <img alt={OpenDataTrustedPartner} src={OpenDataTrustedPartner}/>

                <img alt={CowryTrustedPartner} src={CowryTrustedPartner}/>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-75 max-w-[762px] gap-4 items-center mt-10 lg:mt-0">
            <Typography
              variant="h5"
              className="font-semibold font-clash tracking-two leading-[100%] text-3xl text-center text-[#080912] lg:leading-[40px] lg:text-[40px]"
            >
              Urban Mobilities we provide solutions to
            </Typography>

            <Typography
              variant="h5"
              className="font-normal font-clash text-[#53557A] tracking-two text-[18px] leading-[100%] text-center lg:leading-[29.52px] lg:text-[24px]"
            >
              OrnaMap transforms transportation in Nigeria, starting with Lagos. Easily switch between trains, BRT buses, ferries, and other transport modes with a single tap.
            </Typography>
          </div>

          <div className="my-4 w-full grid grid-cols-1 gap-6 lg:grid-cols-2">
            {/*NRC Lagos - Ibadan*/}
            <Card className="grid grid-cols-1 h-[550px] shadow-none pr-0 overflow-hidden border-[1px] border-[#75757A33] rounded-[20px] lg:col-span-2 lg:grid-cols-2 lg:h-[576px] lg:gap-4">
              <div className="h-full w-full">
                <img src={NRCLagosIbadan} alt={NRCLagosIbadan} className="object-fill bg-no-repeat"/>
              </div>

              <div className="w-full flex flex-col justify-center items-center px-4 lg:px-0">
                  <Typography
                    variant="h3"
                    className="font-medium font-clash tracking-two text-[#080912] w-full text-center text-2xl leading-[100%] lg:text-[32px] lg:leading-[32px]"
                  >
                    Lagos-Ibadan Train
                  </Typography>
                  <Typography
                    variant="paragraph"
                    className="mt-4 font-clash font-normal leading-[100%] tracking-two text-lg text-[#53557A] text-center max-w-[582px] lg:text-[24px] lg:leading-[29.52px]"
                  >
                    Stay informed with live train schedules, fare details, accurate train stops, and easy navigation to nearby stations.
                  </Typography>
              </div>
            </Card>

            {/* Lagos Blue Line Rail */}
            <div
              className="w-full h-[430px] overflow-hidden rounded-[20px] border border-[#75757A33] bg-white justify-start items-center flex flex-col gap-6 lg:h-[604px]"
            >
              <div className="w-full lg:h-[380px]">
                <img width="100%" height="100%" src={LagosBlueLine} alt={LagosBlueLine} className=""/>
              </div>

              <div className="max-w-[528px] items-center flex justify-center flex-col lg:mt-6 lg:gap-3">
                <Typography
                  variant="h3"
                  className="text-2xl w-[270px] font-medium font-clash tracking-two text-[#080912] text-center lg:leading-[32px] lg:text-[32px] lg:w-full"
                >
                  Lagos Blue Line Rail (Mile2 - Marina)
                </Typography>

                <Typography
                  variant="paragraph"
                  className="mt-4 font-clash font-normal tracking-two text-[#53557A] text-center text-lg leading-[100%] lg:leading-[29.52px] lg:text-[24px]"
                >
                  Get real time train schedules, train fares, accurate train stops, easy navigation to train stations.
                </Typography>
              </div>
            </div>

            {/*Lagos Red Line Rail*/}
            <div
              className="w-full h-[430px] overflow-hidden rounded-[20px] border border-[#75757A33] bg-white justify-start items-center flex flex-col lg:gap-6 lg:h-[604px]"
            >

              <div className="w-full lg:h-[380px]">
                <img width="100%" height="100%" src={LagosRedLine} alt={LagosRedLine} className=""/>
              </div>

              <div className="max-w-[586px] items-center flex justify-center flex-col lg:mt-6 lg:gap-3">
                <Typography
                  variant="h3"
                  className="text-2xl w-[270px] font-medium font-clash tracking-two text-[#080912] text-center lg:leading-[32px] lg:text-[32px] lg:w-full"
                >
                  Lagos Red Line Rail (Agbado -Oyingbo)
                </Typography>
                <Typography
                  variant="paragraph"
                  className="mt-4 font-clash font-normal tracking-two text-[#53557A] text-center text-lg leading-[100%] lg:leading-[29.52px] lg:text-[24px]"
                >
                  Move around Lagos Mainland with ease. Plan your trips effortlessly; check out train timetable, ticket fares and train stops with OrnaMap.
                </Typography>
              </div>
            </div>

            {/*Lagos Ferry*/}
            <div
              className="w-full h-[430px] overflow-hidden rounded-[20px] border border-[#75757A33] bg-white justify-start items-center flex flex-col gap-6 lg:h-[604px]"
            >
              <div className="w-full lg:h-[380px] lg:max-h-[400px]">
                <img width="100%" height="100%" src={LagosFerry} alt={LagosFerry} className="max-h-[400px]"/>
              </div>

              <div className="max-w-[586px] flex flex-col items-center justify-center lg:gap-3 lg:mt-6">
                <Typography
                  variant="h3"
                  className="text-2xl w-[270px] font-medium font-clash tracking-two text-[#080912] text-center lg:leading-[32px] lg:text-[32px] lg:w-full"
                >
                  Lagos Ferry
                </Typography>
                <Typography
                  variant="paragraph"
                  className="mt-4 font-clash font-normal tracking-two text-[#53557A] text-center text-lg leading-[100%] lg:leading-[29.52px] lg:text-[24px]"
                >
                  Cut down travel time using Lagos Waterways. OrnaMap provides the best routes, schedules, and real-time updates for a smooth journey across the city.
                </Typography>
              </div>
            </div>

            {/*Lagos BRT Network*/}
            <div
              className="w-full h-[430px] overflow-hidden rounded-[20px] border border-[#75757A33] bg-white justify-start items-center flex flex-col lg:gap-6 lg:h-[604px]"
            >
              <div className="w-full lg:h-[380px] lg:max-h-[400px]">
                <img width="100%" height="100%" src={LagosBRTNetwork} alt={LagosBRTNetwork} className="max-h-[400px]"/>
              </div>

              <div className="max-w-[5868px] items-center flex justify-center flex-col mt-6 lg:gap-3">
                <Typography
                  variant="h3"
                  className="text-2xl w-[270px] font-medium font-clash tracking-two text-[#080912] text-center lg:leading-[32px] lg:text-[32px] lg:w-full"
                >
                  Lagos BRT Network
                </Typography>

                <Typography
                  variant="paragraph"
                  className="mt-4 font-clash font-normal tracking-two text-[#53557A] text-center text-lg leading-[100%] lg:leading-[29.52px] lg:text-[24px]"
                >
                  Find the fastest,  affordable BRT bus routes & options with stop-to-stop navigation across Lagos.
                </Typography>
              </div>
            </div>

          </div>

        </div>
      </div>
    </Fragment>
  );
};

export default OurSolutions;
