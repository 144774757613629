import { Fragment } from 'react';
import PopularRoutesTrain from'../../../assets/img/homepage/img-popular-routes.svg';
import Text from '../../global/Text';
import {FirstRouteSection, SecondRouteSection, ThirdRouteSection} from '../../../lib/constants/constants';

const PopularRoutes = () => {
  return (
    <Fragment>
      <div className="max-w-screen-xl px-2 items-center justify-center w-full">
        <Text
          text={'Popular route'}
          weight={800}
          className={'text-primary text-center mb-5 text-[18px] lg:text-3xl'}
        />

        <div className="grid gap-4 bg-[#ECECF0] rounded-[16px] px-4 grid-cols-2 lg:grid-cols-4">
          <div id={'train_img_container'} className={'hidden lg:flex'}>
            <img src={PopularRoutesTrain} alt={'Popular Routes Train'}/>
          </div>

          <div className={'flex flex-col items-start justify-center py-10'}>
            {FirstRouteSection.map((value, idx) => {
              return (
                <Text
                  key={`${value}-${idx}`}
                  text={value}
                  size={'lg'}
                  weight={500}
                  className={'text-primary !text-[14px] leading-[19px] my-1 lg:!text-lg lg:my-2'}
                />
              );
            })}
          </div>

          <div className={'flex flex-col items-start justify-center py-10'}>
            {SecondRouteSection.map((value, idx) => {
              return (
                <Text
                  key={`${value}-${idx}`}
                  text={value}
                  size={'lg'}
                  weight={500}
                  className={'text-primary my-auto !text-[14px] leading-[19px] my-1 lg:!text-lg lg:my-2'}
                />
              );
            })}
          </div>

          <div className={'hidden flex-col items-start justify-start py-[60px] lg:flex'}>
            {ThirdRouteSection.map((value, idx) => {
              return (
                <Text
                  key={`${value}-${idx}`}
                  text={value}
                  size={'lg'}
                  weight={500}
                  className={'text-primary !text-[14px] leading-[19px] my-1 lg:!text-lg lg:my-2'}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PopularRoutes;