import Layout from '../../Layout';
import HeroSection from '../../components/home/HeroSection';
import OurSolutions from '../../components/home/OurSolutions';
import Features from '../../components/home/Features';
import GetStarted from '../../components/home/GetStarted';
import Testimonials from '../../components/home/Testimonials';
import Bonus from '../../components/home/Bonus';
import AboutOrnaMap from '../../components/home/AboutOrnaMap';
import YourExperience from '../../components/home/YourExperience';

const HomePage = () => {
  return (
    <Layout>
      <HeroSection/>
      
      <OurSolutions/>

      <Features/>

      <GetStarted/>

      <Testimonials/>

      <Bonus/>

      <AboutOrnaMap/>

      <YourExperience/>
    </Layout>
  );
};

export default HomePage;