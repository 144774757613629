import {Fragment} from 'react';
import {Button, Card, Typography} from '@material-tailwind/react';
import AppleLogo from '../../assets/svg/homepage/Apple-Logo.svg';
import PlayStoreLogo from '../../assets/svg/homepage/PlayStore-Logo.svg';
import HeroExploreSvg from '../../assets/svg/homepage/HeroExplore.svg';
import HeroScreenPhone from '../../assets/svg/homepage/HomeScreenPhone.svg';
import {handleDownloadNowClick} from '../../utilities';

const HeroSection = () => {
  return (
    <Fragment>
      <div className="w-full mx-auto flex justify-center px-4 lg:px-0">
        <div className="max-w-[1240px] grid grid-cols-1 gap-6 lg:grid-cols-2">
          {/*Plan Every Step of Your Journey*/}
          <Card
            className="w-full p-5 h-[450px] rounded-none rounded-t-2xl rounded-r-2xl bg-white flex flex-col items-center gap-6 justify-center shadow-none lg:p-10 lg:h-[720px]"
          >
            <Typography
              variant="h3"
              color="blue-gray"
              className="w-full font-semibold text-[#080912] text-[40px] text-left leading-[100%] tracking-two font-clash lg:text-[68px] lg:leading-[68px]"
            >
              Plan every step of your journey
            </Typography>

            <Typography
              variant="paragraph"
              color="blue-gray"
              className="font-normal font-clash text-[#53557A] text-[18px] leading-[100%] tracking-two lg:text-[24px] lg:leading-[29.52px]"
            >
              OrnaMap app provides real-time schedules, step-by-step directions, and seamless ticketing for trains,
              ferries, and buses.
            </Typography>

            <div className="w-full mt-6 flex items-start">
              <Button
                size="sm"
                className="bg-[#282B59] rounded-full p-4 text-white font-medium text-[16px] tracking-two leading-[16px] normal-case font-clash flex items-center hover:shadow-none"
                onClick={handleDownloadNowClick}
              >
                <div className="flex items-center mr-2 gap-2">
                  <img src={AppleLogo} alt={AppleLogo} height={20}/>
                  <div className="min-h-[20px] border-l border-white w-[1px]"/>
                  <img src={PlayStoreLogo} alt={PlayStoreLogo} height={20}/>
                </div>
                <span>Download App Now</span>
              </Button>
            </div>
          </Card>

          {/*Travel Data*/}
          <Card
            className="w-full h-[600px] overflow-hidden pt-10 p-5 rounded-none rounded-t-2xl rounded-l-2xl bg-[#EAEAEE] flex flex-col items-center gap-4 justify-start shadow-none pb-0 lg:h-[720px]"
          >
            <div className="w-full mb-auto h-3/12">
              <img
                src={HeroExploreSvg}
                alt={HeroExploreSvg}
                className="w-full h-full"
              />
            </div>

            <div className="w-full mt-auto justify-center lg:h-9/12 lg:px-20">
              <div className="w-full h-full">
                <img
                  src={HeroScreenPhone}
                  alt={HeroScreenPhone}
                  className="w-full h-full object-contain"
                />
              </div>
            </div>

          </Card>
        </div>
      </div>
    </Fragment>
  );
};

export default HeroSection;
