import Text from '../../global/Text';
import {BsApple, BsDot} from 'react-icons/bs';
import { BsGooglePlay } from 'react-icons/bs';
import  CarHeroIcon from '../../../assets/img/homepage/hero/car.svg';
import  WalkHeroIcon from '../../../assets/img/homepage/hero/walking.svg';
import  TrainHeroIcon from '../../../assets/img/homepage/hero/train.svg';
import  FerryHeroIcon from '../../../assets/img/homepage/hero/ferry.png';
import  TrainBlueLine from '../../../assets/img/homepage/hero/blueline.svg';

import AppUsageImg from '../../../assets/img/homepage/img-hero-discover.png';

const Discover = () => {
  return (
    <div className="max-w-screen-xl items-center justify-center px-2 h-full w-full lg:flex-row grid grid-cols-1 gap-4 lg:gap-16 lg:px-5 lg:grid-cols-[55%_40%]">
      <div className="w-full flex flex-col">
        <Text
          text={'Travel effortlessly with Trains and Ferries using OrnaMap'}
          className="text-center text-white !text-[30px] !font-extrabold leading-normal lg:text-justify lg:text-[48px] lg:!font-bold"
        />

        <Text
          text={'See Realtime Schedules, Trip Fare, Route Suggestion, and Navigation for Lagos Blue Line Metro, Lagos Ferry Service, and Lagos - Ibadan Train Service.'}
          className="text-[16px] text-[#EAEAEE] !font-medium mt-5 leading-[22px] text-center lg:leading-[33px] lg:text-justify lg:text-[24px]"
        />

        <div className={'justify-start items-center mt-10 mb-5 flex px-[12px] lg:px-0'}>
          <img src={CarHeroIcon} loading={'lazy'} alt={'Car Hero Info'} width={50} height={50} className={'hidden lg:flex'}/>
          <img src={CarHeroIcon} loading={'lazy'} alt={'Car Hero Info'} width={40} height={40} className={'flex lg:hidden'}/>

          <BsDot color={'white'} size={50} className={'mx-1'}/>

          <img src={WalkHeroIcon} loading={'lazy'} alt={'Car Hero Info'} width={50} height={50} className={'hidden lg:flex'}/>
          <img src={WalkHeroIcon} loading={'lazy'} alt={'Car Hero Info'} width={40} height={40} className={'flex lg:hidden'}/>

          <BsDot color={'white'} size={50} className={'mx-1'}/>

          <img src={TrainHeroIcon} loading={'lazy'} alt={'Car Hero Info'} width={50} height={50} className={'hidden lg:flex'}/>

          <img src={TrainHeroIcon} loading={'lazy'} alt={'Car Hero Info'} width={40} height={40} className={'flex lg:hidden'}/>

          <BsDot color={'white'} size={50} className={'mx-1'}/>

          <img src={FerryHeroIcon} loading={'lazy'} alt={'Car Hero Info'} width={50} height={50} className={'hidden lg:flex'}/>

          <img src={FerryHeroIcon} loading={'lazy'} alt={'Car Hero Info'} width={40} height={40} className={'flex lg:hidden'}/>

          <BsDot color={'white'} size={50} className={'mx-1'}/>

          <img src={TrainBlueLine} loading={'lazy'} alt={'Car Hero Info'} width={50} height={50} className={'hidden lg:flex'}/>

          <img src={TrainBlueLine} loading={'lazy'} alt={'Car Hero Info'} width={40} height={40} className={'flex lg:hidden'}/>
        </div>

        <div className="flex flex-row w-full my-10 items-center justify-center lg:items-end lg:justify-end max-w-[390px]">
          <a
            type={'button'}
            target={'_blank'}
            href={'https://apps.apple.com/ng/app/ornamap/id6450780439'}
            className="min-w-[145px] text-black bg-white rounded-[7px] text-sm py-3 px-2 inline-flex items-center mr-2
             lg:mr-auto lg:px-5 lg:py-1 store-download-shadow" rel="noreferrer"
          >
            <BsApple color="#282B59" size={30} className={'mr-1 hidden lg:flex'}/>

            <BsApple color="#282B59" size={20} className={'mr-1 flex lg:hidden'}/>

            <div className="flex flex-col ml-2">
              <Text
                text="Available on"
                weight={400}
                className="text-primary text-[12px] leading-[16px] mb-1 lg:text-[14px] lg:leading-[22px] lg:mb-0"
              />

              <Text
                text="App Store"
                weight={700}
                className="text-[16px] text-primary leading-[12px] lg:text-[18px] lg:leading-[25px]"
              />
            </div>
          </a>

          <a
            type="button"
            target={'_blank'}
            href={'https://play.google.com/store/apps/details?id=com.ornamap.android'}
            className="min-w-[145px] text-black bg-white rounded-[7px] text-sm py-3 px-2 inline-flex items-center mr-2
            lg:mr-auto lg:px-5 lg:py-1 store-download-shadow" rel="noreferrer"
          >
            <BsGooglePlay color="#282B59" size={30} className={'mr-1 hidden lg:flex'}/>

            <BsGooglePlay color="#282B59" size={20} className={'mr-1 flex lg:hidden'}/>

            <div className="flex flex-col ml-2">
              <Text
                text="Available on"
                weight={400}
                className="text-primary text-[12px] leading-[16px] mb-1 lg:text-[14px] lg:leading-[22px] lg:mb-0"
              />

              <Text
                text="Play Store"
                weight={700}
                className="text-[16px] text-primary leading-[12px] lg:text-[18px] lg:leading-[25px]"
              />
            </div>
          </a>
        </div>
      </div>

      <div className="hidden lg:flex lg:flex-column">
        <img src={AppUsageImg} alt={'DiscoverOrnaApp Logo'} width={'100%'} height={'100%'} loading={'lazy'}/>
      </div>
    </div>
  );
};

export default Discover;