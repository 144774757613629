import {Fragment, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {analytics, logEvent} from '../lib/firebase';

const CampaignDownloads = () => {
  const location = useLocation();
  const campaignRef = new URLSearchParams(location.search).get('ref');
  // const campaignType = new URLSearchParams(location.search).get('type');

  useEffect(() => {
    let url = '';

    const userAgent = navigator.userAgent;
    const redirectToDownloadLocation = () => {
      if (/android/i.test(userAgent)) {
        url = 'https://play.google.com/store/apps/details?id=com.ornamap.android';
      } else if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !((window as any).MSStream)) {
        url = 'https://apps.apple.com/ng/app/ornamap/id6450780439';
      } else {
        url = `${window.location.origin}/download`;
      }
    };
    
    const captureQueryParam = () => {

      logEvent(analytics, 'download_campaign' , {
        name: campaignRef,
      });
    };

    captureQueryParam();
    redirectToDownloadLocation();

    window.location.href = url;
  }, [campaignRef]);

  return (
    <Fragment>
      <div className="w-full h-screen flex items-center justify-center">Loading...</div>
    </Fragment>
  );
};

export default CampaignDownloads;