import Layout from '../Layout';
import Hero from '../components/faq/Hero';
import {Typography} from '@material-tailwind/react';
import AboutOrnaMap from '../components/faq/AboutOrnaMap';
import YourExperience from '../components/home/YourExperience';

const FAQ = () => {
  return (
    <Layout>
      <Hero/>

      <div className="w-full mx-auto flex justify-center mb-12">
        <div className="w-full h-full max-w-[1240px] flex flex-col items-center justify-center gap-4 lg:gap-8">
          <Typography as="h1" className="font-clash font-semibold text-center tracking-two text-3xl leading-[100%] max-w-[272px] text-[#080912] lg:text-[68px] lg:leading-[68px] lg:max-w-[648px]">Frequently Asked Questions</Typography>

          <Typography as="p" className="font-clash font-normal text-center tracking-two text-base leading-[100%] max-w-[370px] text-[#53557A] lg:leading-[29.52px] lg:text-2xl lg:max-w-[648px]">Got Questions? Answers and advice from the OrnaMap Team</Typography>
        </div>
      </div>

      <AboutOrnaMap/>

      <YourExperience className="mb-10"/>
    </Layout>
  );
};

export default FAQ;
