import {Fragment} from 'react';
import {usePreviewsPage} from '../hooks/usePreviewsPage';
import OrnaMapLogo from '../assets/img/paymentStatus/Logo.svg';
import CowryLogo from '../assets/img/paymentStatus/CowryLogo.png';
import {formatPhoneNumber} from '../utilities';
import {FaArrowRightLong} from 'react-icons/fa6';
import {Spinner, Typography} from '@material-tailwind/react';
import Text from '../components/global/Text';
import moment from 'moment';

const Previews = () => {
  let color = '', message = '', status = '';
  const {jsonPayload} = usePreviewsPage();

  if (jsonPayload) {
    status = jsonPayload?.status?.toLowerCase();
    color = status === 'successful' ? '#2AAF3E' : status === 'failed' ? '#FF3E3E' : '#DF9011';
    message = status === 'successful' ? 'Your Cowry wallet top-up is successful! '
      : status === 'failed' ? 'Your Cowry card top-up attempt is unsuccessful. Please try again or contact support for assistance.'
        : 'Your transaction is being processed, you will receive a notification shortly.';
  }

  return (
    <Fragment>
      <div className={'w-full flex flex-col p-5 my-5 items-center justify-center'}>
        {jsonPayload ? (
          <Fragment>
            <div className={'w-50 flex items-center p-3'}>
              <div className={'h-10 w-10'}>
                <img src={OrnaMapLogo} className={'w-full h-full'} alt={'Payment From OrnaMap'}/>
              </div>

              <FaArrowRightLong className={'mx-4 w-10 h-6'}/>

              <div className={'h-10 w-10'}>
                <img src={CowryLogo} className={'w-full h-full'} alt={'Payment To Cowry'}/>
              </div>
            </div>

            <div className={'flex flex-col my-3 items-center'}>
              <Fragment>
                <Text
                  className={'!font-extrabold !text-2xl !text-primary'}
                  text={`₦${Number(jsonPayload.amount).toLocaleString()}`}
                />
              </Fragment>

              <p
                className="leading-7 !no-underline font-nunito text-left not-italic normal-nums tracking-normal indent-0 break-normal !font-bold !text-base uppercase"
                style={{color}}
              >
                {`Funding ${status}`}
              </p>

              <Text
                text={message}
                className={'!font-medium !text-base !text-primary text-center mt-6'}
              />

              <Text text={moment(jsonPayload?.createdAt).format('h:mm A | DD MMM YYYY')}
                    className={'!font-semibold !text-base !text-primary text-center mt-1'}/>
            </div>

            <div className={'w-full flex flex-col bg-[#EAEAEE] p-2 my-3 rounded'}>
              <div className={'flex w-full justify-start'}>
                <Text text={'Recipient Name'}
                      className={'!font-semibold !text-base !text-[#53557A] text-left mr-auto'}/>

                <Text text={jsonPayload?.metadata?.recipientName}
                      className={'!font-bold !text-base !text-primary text-right ml-auto'}/>
              </div>

              <div className={'h-[1px] w-full bg-[#BFBFCD] my-3'}></div>

              <div className={'flex w-full justify-start'}>
                <Text text={'Recipient Number'}
                      className={'!font-semibold !text-base !text-[#53557A] text-left mr-auto'}/>

                <Text text={formatPhoneNumber(Number(jsonPayload?.phone)) ?? ''}
                      className={'!font-bold !text-base !text-primary text-right ml-auto'}/>
              </div>

              <div className={'h-[1px] w-full bg-[#BFBFCD] my-3'}></div>

              <div className={'flex w-full justify-start'}>
                <Text text={'Fees'} className={'!font-semibold !text-base !text-[#53557A] text-left mr-auto'}/>

                <Text text={`₦${Number(jsonPayload?.fee).toLocaleString()}`}
                      className={'!font-bold !text-base !text-primary text-right ml-auto'}/>
              </div>

              <div className={'h-[1px] w-full bg-[#BFBFCD] my-3'}></div>

              <div className={'flex w-full justify-start'}>
                <Text text={'Reference'} className={'!font-semibold !text-base !text-[#53557A] mr-4 text-left'}/>

                <Text text={jsonPayload.transactionId ?? ''}
                      className={'!font-bold !text-base !text-primary text-right ml-auto truncate'}/>
              </div>
            </div>

            <div className="mt-5">
              <Text
                text={'Tap on [X] to close'}
                className={'text-base text-primary font-bold normal-case'}
                weight={700}
              />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div
              className={'flex flex-col items-center justify-center gap-4 h-screen'}>
              <Spinner className="h-16 w-16 text-gray-900/50"/>

              <Typography
                variant={'h4'}
                color={'gray'}
                className={'font-bold text-center'}>
                Loading...
              </Typography>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Previews;