import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {ChevronDownIcon} from 'lucide-react';
import {
  Button,
  MobileNav,
  Navbar,
  Typography,
  IconButton,
  // Popover,
  // PopoverHandler,
  // PopoverContent
} from '@material-tailwind/react';
import NewOrnaMapLogo from '../../assets/svg/homepage/New-Logo.svg';
import AppleLogo from '../../assets/svg/homepage/Apple-Logo.svg';
import PlayStoreLogo from '../../assets/svg/homepage/PlayStore-Logo.svg';
import {handleDownloadNowClick} from '../../utilities';
// import TrainBlueLine from '../../assets/svg/global/Train-Blue-Line.svg';
// import TrainRedLine from '../../assets/svg/global/Train-Red-Line.svg';
// import TrainNRCLine from '../../assets/svg/global/Train-NRC-Line.svg';

const Header = () => {
  const [openNav, setOpenNav] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const location = window.location.pathname;

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);

  const triggers = {
    onMouseEnter: () => setOpenPopover(true),
    onMouseLeave: () => setOpenPopover(false),
  };

  const navigateToHome = () => {
    window.location.href = '/';
  };


  return (
    <Fragment>
      <div className="w-full mx-auto flex justify-center">
        <Navbar className="sticky bg-transparent border-none shadow-none top-0 z-10 h-max max-w-[1240px] rounded-none px-4 py-2 lg:py-4 lg:px-0">
          <div className="flex items-center justify-between text-blue-gray-900 max-w-[1240px] mx-auto">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            <img onClick={navigateToHome} src={NewOrnaMapLogo} alt={NewOrnaMapLogo} height={40} className="hover:cursor-pointer"/>

            {/*Nav List*/}
            <div className="mx-auto hidden lg:block">
              <Fragment>
                <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
                  {/*Home*/}
                  <div>
                    <Typography
                      as="li"
                      variant="small"
                      color="blue-gray"
                      className="p-1"
                    >
                      <Link to="/" className={`font-clash font-medium text-[18px] tracking-four leading-[22.14px] ${location === '/' ? 'text-[#080912]' : 'text-[#75757A]'}`}>Home</Link>
                    </Typography>
                  </div>

                  {/*Blog*/}
                  <div>
                    <Typography
                      as="li"
                      variant="small"
                      color="blue-gray"
                      className="p-1 font-normal"
                    >
                      <Link to="/" className={`font-clash font-medium text-[18px] tracking-four leading-[22.14px] ${location.includes('/blog') ? 'text-[#080912]' : 'text-[#75757A]'}`}>Blog</Link>
                    </Typography>
                  </div>

                  {/*/!*Schedule*!/*/}
                  {/*<Popover placement="bottom" open={openPopover} animate={{*/}
                  {/*  mount: { scale: 1, y: 0 },*/}
                  {/*  unmount: { scale: 0, y: 25 },*/}
                  {/*}}>*/}
                  {/*  <PopoverHandler {...triggers}>*/}
                  {/*    <Typography*/}
                  {/*      as="li"*/}
                  {/*      variant="small"*/}
                  {/*      color="blue-gray"*/}
                  {/*      className="p-1 flex gap-2"*/}
                  {/*    >*/}
                  {/*      <Link to="/" className={`flex justify-center items-center font-clash font-medium text-[18px] tracking-four leading-[22.14px] ${location === '/schedule' ? 'text-[#080912]' : 'text-[#75757A]'}`}>Schedule <ChevronDownIcon size={20} className={`ml-2 ${openPopover ? 'rotate-180' : ''}`}/></Link>*/}
                  {/*    </Typography>*/}
                  {/*  </PopoverHandler>*/}

                  {/*  <PopoverContent className="z-50 w-[375px] flex flex-col px-4 gap-2"  {...triggers}>*/}
                  {/*    /!*Blue Line*!/*/}
                  {/*    <div*/}
                  {/*      className="flex items-center gap-3 w-full rounded-[4px] hover:cursor-pointer py-[10.5px] pl-[8px] hover:bg-[#F6F7F8]">*/}
                  {/*      <div className="p-2 bg-[#EAEAEE] rounded-[2px]">*/}
                  {/*        <img src={TrainBlueLine} alt={TrainBlueLine}/>*/}
                  {/*      </div>*/}

                  {/*      <div className="flex flex-col">*/}
                  {/*        <Typography*/}
                  {/*          className="text-[#0183BA] font-clash font-semibold text-[16px] leading-[16px] tracking-two">Blue*/}
                  {/*          Line</Typography>*/}
                  {/*        <Typography*/}
                  {/*          className="text-[#53557A] font-clash font-normal text-[14px] leading-[17.22px] tracking-two">View*/}
                  {/*          the Lagos blue line train Schedule</Typography>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}

                  {/*    <div className="bg-[#D9D9D9] w-full h-px"/>*/}

                  {/*    /!*Red Line*!/*/}
                  {/*    <div*/}
                  {/*      className="flex items-center gap-3 w-full rounded-[4px] hover:cursor-pointer py-[10.5px] pl-[8px] hover:bg-[#F6F7F8]">*/}
                  {/*      <div className="p-2 bg-[#EAEAEE] rounded-[2px]">*/}
                  {/*        <img src={TrainRedLine} alt={TrainRedLine}/>*/}
                  {/*      </div>*/}

                  {/*      <div className="flex flex-col">*/}
                  {/*        <Typography*/}
                  {/*          className="text-[#FF3E3E] font-clash font-semibold text-[16px] leading-[16px] tracking-two">Red*/}
                  {/*          Line</Typography>*/}
                  {/*        <Typography*/}
                  {/*          className="text-[#53557A] font-clash font-normal text-[14px] leading-[17.22px] tracking-two">View*/}
                  {/*          the Lagos red line train Schedule</Typography>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}

                  {/*    <div className="bg-[#D9D9D9] w-full h-px"/>*/}

                  {/*    /!*NRC Line*!/*/}
                  {/*    <div*/}
                  {/*      className="flex items-center gap-3 w-full rounded-[4px] hover:cursor-pointer py-[10.5px] pl-[8px] hover:bg-[#F6F7F8]">*/}
                  {/*      <div className="p-2 bg-[#EAEAEE] rounded-[2px]">*/}
                  {/*        <img src={TrainNRCLine} alt={TrainNRCLine}/>*/}
                  {/*      </div>*/}

                  {/*      <div className="flex flex-col">*/}
                  {/*        <Typography*/}
                  {/*          className="text-[#008751] font-clash font-semibold text-[16px] leading-[16px] tracking-two">NRC*/}
                  {/*          Line</Typography>*/}
                  {/*        <Typography*/}
                  {/*          className="text-[#53557A] font-clash font-normal text-[14px] leading-[17.22px] tracking-two">View*/}
                  {/*          the Lagos NRC line train Schedule</Typography>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*  </PopoverContent>*/}
                  {/*</Popover>*/}

                  {/*FAQs*/}
                  <div>
                    <Typography
                      as="li"
                      variant="small"
                      color="blue-gray"
                      className="p-1 font-normal"
                    >
                      <Link to="/faq"
                            className={`font-clash font-medium text-[18px] tracking-four leading-[22.14px] ${location === '/faq' ? 'text-[#080912]' : 'text-[#75757A]'}`}>FAQs</Link>
                    </Typography>
                  </div>
                </ul>
              </Fragment>
            </div>

            <div className="flex items-center justify-between gap-4">
              <div className="flex items-center gap-x-1">
              <Button
                  size="sm"
                  className="hidden bg-[#282B59] rounded-full p-4 text-white font-medium text-[16px] tracking-two lrading-[16px] normal-case font-clash lg:flex lg:items-center hover:shadow-none"
                  onClick={handleDownloadNowClick}
                >
                  <div className="flex items-center mr-2 gap-2">
                    <img src={AppleLogo} alt={AppleLogo} height={20} />
                    <div className="min-h-[20px] border-l border-white w-[1px]"/>
                    <img src={PlayStoreLogo} alt={PlayStoreLogo} height={20} />
                  </div>
                  <span>Download App Now</span>
                </Button>
              </div>

              <IconButton
                variant="text"
                className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                ripple={false}
                onClick={() => setOpenNav(!openNav)}
              >
                {openNav ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    className="h-6 w-6"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </IconButton>
            </div>
          </div>

          <MobileNav className="flex lg:hidden" open={openNav}>
            {/*Navlist*/}
            <Fragment>
              <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
                {/*Home*/}
                <div>
                  <Typography
                    as="li"
                    variant="small"
                    color="blue-gray"
                    className="p-1"
                  >
                    <Link to="/" className={`font-clash font-medium text-[18px] tracking-four leading-[22.14px] ${location === '/' ? 'text-[#080912]' : 'text-[#75757A]'}`}>Home</Link>
                  </Typography>
                </div>

                {/*Blog*/}
                <div>
                  <Typography
                    as="li"
                    variant="small"
                    color="blue-gray"
                    className="p-1 font-normal"
                  >
                    <Link to="/" className={`font-clash font-medium text-[18px] tracking-four leading-[22.14px] ${location.includes('/blog') ? 'text-[#080912]' : 'text-[#75757A]'}`}>Blog</Link>
                  </Typography>
                </div>

                {/*Schedule*/}
                <div>
                  <Typography
                    as="li"
                    variant="small"
                    color="blue-gray"
                    className="p-1 flex gap-2"
                  >
                    <Link to="/" className={`flex justify-center items-center font-clash font-medium text-[18px] tracking-four leading-[22.14px] ${location === '/schedule' ? 'text-[#080912]' : 'text-[#75757A]'}`}>Schedule <ChevronDownIcon size={20} className={`ml-2 ${openPopover ? 'rotate-180' : ''}`}/></Link>
                  </Typography>
                </div>


                {/*FAQs*/}
                <div>
                  <Typography
                    as="li"
                    variant="small"
                    color="blue-gray"
                    className="p-1 font-normal"
                  >
                    <Link to="/" className={`font-clash font-medium text-[18px] tracking-four leading-[22.14px] ${location === '/faq' ? 'text-[#080912]' : 'text-[#75757A]'}`}>FAQs</Link>
                  </Typography>
                </div>
              </ul>
            </Fragment>

            <div className="flex items-center justify-center gap-x-1 w-full">
              <Button
                className="hidden bg-[#282B59] rounded-full p-4 text-white font-medium text-[16px] tracking-two lrading-[16px] normal-case font-clash lg:flex lg:items-center hover:shadow-none"
                fullWidth
                onClick={handleDownloadNowClick}
              >
                <div className="flex items-center mr-2 gap-2">
                  <img src={AppleLogo} alt={AppleLogo} height={40} />
                  <div className="min-h-[20px] border-l border-white w-[1px]"/>
                  <img src={PlayStoreLogo} alt={PlayStoreLogo} height={40} />
                </div>
                <span>Download App Now</span>
              </Button>
            </div>
          </MobileNav>
        </Navbar>
      </div>
    </Fragment>
  );
};

export default Header;
