import { Minus, Plus } from 'lucide-react';
import {Typography} from '@material-tailwind/react';

interface FaqItemProps {
  question: string;
  answer: string;
  isOpen: boolean;
  index: number;
  onToggle: () => void;
}

const FaqItem = ({ question, answer, isOpen, index, onToggle }: FaqItemProps) => {
  return (
    <div className={'border-b border-[#00000033] last:border-b-0 py-[32px]'} key={index}>
      <button
        onClick={onToggle}
        className={'w-full flex items-center justify-between text-left hover:cursor-pointer transition-colors'}
      >
        <div className="flex items-center gap-3">
          <div className="flex-shrink-0">
            {isOpen ? (
              <Minus className="w-3 h-3 text-[#080912] lg:w-6 lg:h-6" />
            ) : (
              <Plus className="w-3 h-3 text-[#080912] lg:w-6 lg:h-6" />
            )}
          </div>
          <Typography className="font-medium tracking-two text-lg leading-[100%] font-clash text-[#080912] lg:text-2xl lg:leading-[24px]">{question}</Typography>
        </div>
      </button>
      {isOpen && (
        <div className="p-0  ml-[35px]">
          <Typography className={`text-[#53557A] font-clash text-base leading-[100%] font-normal tracking-two lg:leading-[30.24px] lg:text-lg ${isOpen ? 'mt-[12px]' : ''}`}>{answer}</Typography>
        </div>
      )}
    </div>
  );
};

export default FaqItem;