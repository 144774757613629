import {Fragment, useState} from 'react';
import {Button, Typography} from '@material-tailwind/react';
import FaqItem from './FagItem';

const AboutOrnaMap = () => {
  const [openItems, setOpenItems] = useState(new Set(['1']));

  const faqItems = [
    {
      id: '1',
      question: 'What is OrnaMap?',
      answer: 'OrnaMap is a mobile app designed to streamline transit navigation across Nigeria, providing real-time information for trains, ferries, and buses. With features like schedule search, fare details, route suggestions, and navigation, OrnaMap helps users make informed travel decisions and plan their journeys with ease.'
    },
    {
      id: '2',
      question: 'Which transit services does OrnaMap support?',
      answer: 'OrnaMap currently supports the Lagos Blue Line Metro, Red-Line Train, Lagos Ferry Service BRT buses and the Lagos-Ibadan Train Service. We’re continuously expanding our network to provide comprehensive transit options across Nigeria.'
    },
    {
      id: '3',
      question: 'How does the Cowry Card funding feature work?',
      answer: 'OrnaMap offers an easy way to top up your Cowry Card balance directly within the app. By linking to your preferred payment method, you can add funds anytime, ensuring a cashless, hassle-free experience across supported trains, ferries, and buses.'
    },
  ];

  const toggleItem = (itemId: string) => {
    setOpenItems(prev => {
      if (prev.has(itemId)) {
        return new Set();
      }
      return new Set([itemId]);
    });
  };

  const navigateToFAQ = () => {
    window.location.href = '/faq';
  };

  return (
    <Fragment>
      <div className="w-full bg-gradient-to-b from-[#12A6284D] to-[#FFFFFF4D] h-[800px] flex justify-center py-16 px-4 lg:h-[700px] lg:px-0">
        <div className="w-full h-full max-w-[1240px] flex flex-col items-center justify-center lg:gap-16 lg:flex-row">
          <div className="h-full flex flex-col justify-start gap-4 w-full max-h-[300px] lg:max-h-[400px] lg:max-w-[600px] lg:w-1/3">
            <div className="bg-white px-5 py-3 rounded-full h-[46px] w-fit">
              <Typography variant="small" className="font-clash font-normal text-lg text-[#080912] leading-[100%] tracking-two lg:leading-[22.14px]">
                Got Questions?
              </Typography>
            </div>

            <Typography variant="h2" className="text-[#080912] font-clash text-[32px] leading-[100%] font-semibold tracking-two max-w-[295px] lg:text-[40px] lg:leading-[40px]">
              Some of the things you may want to know
            </Typography>

            <Typography className="font-clash font-normal text-lg leading-[100%] tracking-two max-w-[295px] text-[#53557A] mb-0 lg:mb-[32px] lg:text-2xl lg:leading-[29.52px]">
              We’ve answered them so you don’t have to ask.
            </Typography>

            <Button onClick={navigateToFAQ} className="font-clash bg-[#282B59] normal-case px-6 py-5 mt-2 rounded-full text-base text-white font-medium w-fit tracking-two lg:leading-[19.68px]">See more FAQs</Button>
          </div>

          <div className="w-full h-full lg:w-2/3">
            {/*<div className={`divide-[#00000033] divide-y`}>*/}
            <div className="w-full">
              {faqItems.map((item, index) => (
                <FaqItem
                  key={item.id}
                  question={item.question}
                  answer={item.answer}
                  index={index}
                  // Close others, Only open the one where the id matches the id of the item
                  isOpen={openItems.has(item.id)}
                  onToggle={() => toggleItem(item.id)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AboutOrnaMap;
