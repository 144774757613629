import {Fragment} from 'react';
import {Button, Typography} from '@material-tailwind/react';

import ExperienceUsers from '../../assets/img/homepage/Experience-Users.png';
import ExperienceScreen from '../../assets/img/homepage/Experience-Screen.png';
import AppleLogo from '../../assets/svg/homepage/Apple-Logo.svg';
import PlayStoreLogo from '../../assets/svg/homepage/PlayStore-Logo.svg';
import TestimonialsVector from '../../assets/svg/global/TestimonialsVector.svg';
import {handleDownloadNowClick} from '../../utilities';

const YourExperience = ({ className = '' }: { className?: string }) => {
  return (
    <Fragment>
      <div className={`w-full mx-auto flex justify-center overflow-hidden ${className}`}>
        <div className="w-full flex flex-col gap-4 items-center justify-center my-20 max-w-[1240px]">
          <div className="h-[650px] w-full bg-[#F2F2F5] rounded-[30px] flex flex-col items-center justify-center gap-4 overflow-hidden lg:flex-row lg:gap-16 lg:h-[570px] lg:items-start lg:justify-start">
            <div className="flex flex-col w-full px-4 pt-16 gap-4 max-h-[330px] lg:w-1/2 lg:px-16 lg:pt-16 lg:gap-12">
              <Typography
                className="text-[#54557A] font-clash font-medium text-lg leading-[100%] tracking-two">
                Download OrnaMap Now!
              </Typography>

              <Typography
                className="text-[#080912] font-clash font-semibold text-3xl leading-[100%] tracking-two max-w-[338px] lg:max-w-[528px] lg:text-[68px]">
                Navigate Your City with Ease
              </Typography>

              <div className="hidden gap-4 items-center w-[512px] overflow-hidden">
                <div className="h-[85px] w-[300px]">
                  <img src={ExperienceUsers} alt={ExperienceUsers}/>
                </div>

                <Typography className="text-[#53557A] leading-[24px] text-2xl font-medium font-clash tracking-two">
                  35K+ Users
                </Typography>
              </div>

              <div className="flex items-center gap-x-1 max-w-[248px]">
                <Button
                  size="sm"
                  className="flex items-center justify-center bg-[#282B59] w-full rounded-full p-4 text-white font-medium text-[16px] tracking-two leading-[100%] normal-case font-clash lg:flex lg:items-center hover:shadow-none lg:leading-[16px]"
                  onClick={handleDownloadNowClick}
                >
                  <div className="flex items-center mr-2 gap-2">
                    <img src={AppleLogo} alt={AppleLogo} height={20}/>
                    <div className="min-h-[20px] border-l border-white w-[1px]"/>
                    <img src={PlayStoreLogo} alt={PlayStoreLogo} height={20}/>
                  </div>
                  <span>Download App Now</span>
                </Button>
              </div>
            </div>

            <div className='absolute mt-[540px] lg:mt-[351px]'>
              <img src={TestimonialsVector} alt={TestimonialsVector} className='w-full h-full'/>
            </div>

            <div className="max-w-[385px] w-[250px] h-full pt-16 flex z-10 lg:w-1/2">
              <img src={ExperienceScreen} alt={ExperienceScreen}/>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default YourExperience;
