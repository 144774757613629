import AboutUsHero from '../components/about/AboutUsHero';
import Layout from '../Layout';
import YourExperience from '../components/home/YourExperience';

const AboutUsNew = () => {
  return (
    <Layout>
      <AboutUsHero/>

      <div className="py-10">
        <YourExperience/>
      </div>
    </Layout>
  );
};

export default AboutUsNew;