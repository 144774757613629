import {Fragment} from 'react';
import {Card, Typography} from '@material-tailwind/react';
import FeaturesStepByStep from '../../assets/img/homepage/Features-Step-By-Step.png';
import FeaturesTicketing from '../../assets/img/homepage/Features-Ticketing.png';
import FeaturesTicketingCowry from '../../assets/img/homepage/Features-Ticketing-Cowry.png';
import FeaturesTrackingOpions from '../../assets/img/homepage/Features-Tracking-Options.png';
import FeatureSchedule from '../../assets/img/homepage/Features-Schedule.png';
import FeatureScheduleWalkDrive from '../../assets/img/homepage/Features-Walk-Drive.png';
import FeaturesGo from '../../assets/img/homepage/Features-Go.png';

const Features = () => {
  return (
    <Fragment>
      <div className="w-full mx-auto flex justify-center px-4 lg:px-0">
        <div className="w-full max-w-[1240px] flex flex-col gap-4 items-center justify-center my-20">
          <div className="bg-[#D0EDD4] rounded-full px-8 py-3">
            <Typography variant="paragraph" className="font-clash text-[#080912] font-normal text-[18px] leading-[18px] tracking-two">
              Features
            </Typography>
          </div>

          <Typography
            variant="h2"
            className="text-[#080912] font-semibold text-[32px] leading-[100%] tracking-two text-center max-w-[720px] font-clash lg:text-[40px] lg:leading-[40px]"
          >
            From Planning to Arrival: A Complete Look at OrnaMap’s Features
          </Typography>

          <div className="w-full my-10 grid grid-cols-1 gap-6 lg:grid-cols-2">
            {/*Step By Step*/}
            <Card
              className="w-full flex flex-col items-center h-[666px] rounded-[30px] pt-8 px-12 overflow-hidden shadow-none gap-10">
              <div className="w-full flex flex-col item-center justify-center gap-2 h-[76px]">
                <Typography variant="h4"
                            className="text-[#080912] font-medium text-2xl font-clash leading-[24px] tracking-two w-full text-center">
                  Step-by-Step
                </Typography>

                <Typography variant="paragraph"
                            className="w-full font-clash font-normal text-lg text-center leading-[22.14px] tracking-two text-[#53557A]">
                  Clear directions for every step, turn, and transit connection - from start to finish.
                </Typography>
              </div>

              <div className="h-[480px] w-[390px]">
                <img
                  src={FeaturesStepByStep}
                  alt={FeaturesStepByStep}
                  className="object-fit h-full w-full"
                />
              </div>
            </Card>

            {/*Ticketing*/}
            <Card
              className="w-full flex flex-col items-center h-[670px] rounded-[30px] pt-8 overflow-hidden shadow-none gap-10">
              <div className="w-full flex flex-col item-center justify-center gap-2 h-[76px] px-12">
                <Typography variant="h4"
                            className="text-[#080912] font-medium text-2xl font-clash leading-[24px] tracking-two w-full text-center">
                  Ticketing
                </Typography>

                <Typography variant="paragraph"
                            className="w-full font-clash font-normal text-lg text-center leading-[22.14px] tracking-two text-[#53557A]">
                  Check for live departure and arrival times for all transit options - plan your journey and stay on
                  schedule.
                </Typography>
              </div>

              <div className="w-[390px] h-[809px]">
                <img
                  src={FeaturesTicketing}
                  alt={FeaturesTicketing}
                  className="object-fit h-full w-full"
                />
              </div>

              <div className="relative z-10 w-[240px] h-[240px] left-[210px] top-[-244px]">
                <img src={FeaturesTicketingCowry} alt={FeaturesTicketingCowry}/>
              </div>
            </Card>

            {/*Transit Options & Fare*/}
            <Card
              className="w-full flex flex-col items-center h-[670px] rounded-[30px] pt-8 px-12 overflow-hidden shadow-none gap-12">
              <div className="w-full flex flex-col item-center justify-center gap-2 h-[76px]">
                <Typography variant="h4"
                            className="text-[#080912] font-medium text-2xl font-clash leading-[24px] tracking-two w-full text-center">
                  Transit Options & Fare
                </Typography>

                <Typography variant="paragraph"
                            className="w-full font-clash font-normal text-lg text-center leading-[22.14px] tracking-two text-[#53557A]">
                  Check real-time fares across all transit options - plan your trip and budget in seconds.
                </Typography>
              </div>

              <div className="h-[680px]">
                <img
                  src={FeaturesTrackingOpions}
                  alt={FeaturesTrackingOpions}
                  className="object-fit h-full w-full"
                />
              </div>
            </Card>

            {/*Schedule*/}
            <Card
              className="w-full flex flex-col items-center h-[670px] rounded-[30px] pt-8 px-12 overflow-hidden shadow-none gap-10">
              <div className="w-full flex flex-col item-center justify-center gap-2 h-[76px]">
                <Typography variant="h4"
                            className="text-[#080912] font-medium text-2xl font-clash leading-[24px] tracking-two w-full text-center">
                  Schedule
                </Typography>

                <Typography variant="paragraph"
                            className="w-full font-clash font-normal text-lg text-center leading-[22.14px] tracking-two text-[#53557A]">
                  Check for live departure and arrival times for all transit options - plan your journey and stay on schedule.
                </Typography>
              </div>

              <div className="h-[512px] w-[390px]">
                <img
                  src={FeatureSchedule}
                  alt={FeatureSchedule}
                  className="object-fit h-full w-full"
                />
              </div>
            </Card>

            {/*Walk & Drive*/}
            <Card
              className="w-full flex flex-col items-center h-[670px] rounded-[30px] pt-8 px-12 overflow-hidden shadow-none gap-10">
              <div className="w-full flex flex-col item-center justify-center gap-2 h-[76px]">
                <Typography variant="h4"
                            className="text-[#080912] font-medium text-2xl font-clash leading-[24px] tracking-two w-full text-center">
                  Walk & Drive
                </Typography>

                <Typography variant="paragraph"
                            className="w-full font-clash font-normal text-lg text-center leading-[22.14px] tracking-two text-[#53557A]">
                  Get directions to and from any transit stop - whether you{'\''}re walking or driving, OrnaMap will guide you every step of the way.
                </Typography>
              </div>

              <div className="w-[390px]">
                <img
                  src={FeatureScheduleWalkDrive}
                  alt={FeatureScheduleWalkDrive}
                  className="object-fit h-full w-full"
                />
              </div>
            </Card>

            {/*GO!*/}
            <Card
              className="w-full flex flex-col items-center h-[670px] rounded-[30px] pt-8 px-12 overflow-hidden shadow-none gap-10">
              <div className="w-full flex flex-col item-center justify-center gap-2 h-[76px]">
                <Typography variant="h4"
                            className="text-[#080912] font-medium text-2xl font-clash leading-[24px] tracking-two w-full text-center">
                  GO!
                </Typography>

                <Typography variant="paragraph"
                            className="w-full font-clash font-normal text-lg text-center leading-[22.14px] tracking-two text-[#53557A]">
                  Hit the <strong>{'\''}Go{'\''}</strong> button to kick off your trip! Whether you{'\''}re taking the train, ferry, or bus, OrnaMap gets you moving with just one tap.
                </Typography>
              </div>

              <div className="h-[480px] w-[390px]">
                <img
                  src={FeaturesGo}
                  alt={FeaturesGo}
                  className="object-fit h-full w-full"
                />
              </div>
            </Card>

          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Features;
