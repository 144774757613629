import {useEffect, useState} from 'react';
import {axiosGetRequest} from '../lib/axios';
import {BlogPostMiniProps} from '../types/blog';

export const useBlogPage = () => {
    const [blogData, setBlogData] = useState<BlogPostMiniProps[] | null>([]);
    const [limit, setLimit] = useState(8);

    useEffect(() => {
        const fetchBlogData = async () => {
            const response = await axiosGetRequest('/blog/most-popular', {limit});
            if (response.success) {
                setBlogData(response.data as BlogPostMiniProps[]);
                window.scrollTo({top: 0, behavior: 'auto'});
            }
        };

        fetchBlogData();
    }, [limit]);


    const showMore = async () => {
        setLimit(limit + 8);
        const response = await axiosGetRequest('/blog/most-popular', {limit: limit + 8});
        if (response.success) {
            setBlogData(response.data as BlogPostMiniProps[]);
        }
    };

    return {
        blogData,

        showMore
    };
};
