import {Fragment} from 'react';
import BackgroundShortImage from '../assets/img/global/bg-hero-short-section.jpg';
import HeaderWithBgImg from '../components/global/HeaderWithBgImg';
import Text from '../components/global/Text';
import BlogItem from '../components/blog/BlogItem';
import {useBlogPage} from '../hooks/useBlogPage';
import Footer from '../components/global/Footer';
import moment from 'moment';

const BlogPage = () => {
    const {
        blogData,

        showMore,
    } = useBlogPage();

    return (
        <Fragment>
            <HeaderWithBgImg bgImg={BackgroundShortImage}/>

            <main >
                <section id="blog_page" className="flex min-h-[500px] mt-[70px] items-center justify-center px-0 py-10 lg:px-4 lg:px-10 lg:pb-20 lg:pt-[155px] lg:min-h-[900px] lg:mt-0">
                    <div className={'max-w-screen-xl flex flex-col w-full min-h-[400px] h-full px-4'}>

                        <Text
                            weight={700}
                            className={'text-primary text-center leading-normal lg:text-[48px]'}
                            text={'Explore Seamless Travel Experiences with OrnaMap: Your Ultimate Journey Companion!'}
                        />

                        <Text
                            weight={500}
                            className={'text-[#53557A] text-center leading-normal lg:text-[24px] lg:px-20'}
                            text={`Discover the inner workings of your city's transport infrastructure, effortlessly staying 
                        updated with real-time schedules, fare changes, and the most efficient routes.`}
                        />

                        <div className={'my-10 flex flex-col w-full h-full'}>
                            {blogData && blogData?.length !== 0 && (
                                <>
                                    <BlogItem
                                        blogImgURL={blogData[0]?.imgUrl}
                                        bodyHeader={blogData[0]?.title}
                                        bodyText={typeof blogData[0]?.introContent === 'string' ? blogData[0]?.introContent : blogData[0]?.introContent[0]}
                                        date={moment(blogData[0]?.created_at).format('MMMM Do YYYY')}
                                        writer={blogData[0]?.writer}
                                        cardClassName={'h-[700px] w-full max-h-[1000px]'}
                                        headerClassName={''}
                                        bodyHeaderClassName={'text-[30px] font-extrabold text-primary'}
                                        bodyTextClassName={'leading-normal font-normal text-[18px] text-justify text-[#53557A] line-clamp-2'}
                                        showFooter={true}
                                        id={blogData[0]?.id}
                                    />
                                </>
                            )}

                            <div className={'flex flex-col w-full h-full mt-24 items-center justify-center'}>
                                {blogData && blogData.length !== 0 && (
                                    <Text
                                        weight={700}
                                        className={'text-primary text-center leading-normal font-extrabold lg:text-[30px]'}
                                        text={'Most Popular'}
                                    />
                                )}

                                <div className={'grid grid-cols-1 gap-6 mt-5 lg:grid-cols-2'}>
                                    {blogData && blogData?.length !== 0 && blogData?.map((item) => {
                                        return (
                                            <div key={item?.id}>
                                                <BlogItem
                                                    blogImgURL={item?.imgUrl}
                                                    bodyHeader={item?.title}
                                                    bodyText={item?.introContent}
                                                    date={moment(item?.created_at).format('MMMM Do YYYY')}
                                                    writer={item?.writer}
                                                    bodyClassName={'pb-[10px]'}
                                                    cardClassName={'h-[500px] w-full max-h-[1000px]'}
                                                    bodyHeaderClassName={'text-[25px] font-extrabold text-primary line-clamp-1'}
                                                    bodyTextClassName={'leading-normal font-normal text-[16px] text-justify text-[#53557A] line-clamp-3'}
                                                    showFooter={true}
                                                    headerClassName={'h-[80%]'}
                                                    id={item.id}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>

                                {blogData && blogData.length >= 8 && (
                                    <button type="button" onClick={showMore}
                                            className=" mt-10 font-medium rounded-[8px] text-white text-base font-bold px-10 py-2 text-center mr-3 lg:mr-0 bg-[#12A628] flex hover:text-[#696B8B]">Load More
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </section>

                <Footer/>
            </main>
        </Fragment>
    );
};

export default BlogPage;