import {Fragment} from 'react';
import {Card, Typography} from '@material-tailwind/react';
import HeroExploreSvg from '../../assets/svg/homepage/HeroExplore.svg';
import HeroScreenPhone from '../../assets/img/aboutUs/OrnaMapDeviceHome.png';

const AboutUsHero = () => {
  return (
    <Fragment>
      <div className="w-full mx-auto flex justify-center px-4 lg:px-0">
        <div className="max-w-[1240px] w-full flex flex-col">
          {/*About Intro*/}
          <div className="w-full flex flex-col items-center justify-center gap-3 mb-20 lg:mb-0">
            <Typography
              variant="h1"
              color="blue-gray"
              className="w-full text-left font-semibold text-[#080912] text-3xl leading-[100%] tracking-two font-clash lg:text-[68px] lg:leading-[68px] lg:text-center"
            >
              Our Mission
            </Typography>

            <Typography
              variant="paragraph"
              color="blue-gray"
              className="w-full font-medium tracking-two text-base -ml-7 leading-[100%] text-left text-[#53557A] font-clash max-w-[370px] lg:max-w-[691px] lg:text-[24px] lg:leading-[40.32px] lg:text-center lg:ml-0"
            >
              Our mission is to empower seamless and efficient travel experiences
              for everyone by providing innovative, real-time transit navigation
              solutions.
            </Typography>
          </div>

          {/*AboutHero*/}
          <div className="w-full flex flex-col-reverse lg:grid lg:gap-[68px] lg:grid-cols-[40%_60%] lg:mt-[148px]">
            <Card
              className="w-full h-[600px] overflow-hidden pt-10 p-5 rounded-none rounded-t-2xl rounded-l-2xl bg-[#EAEAEE] flex flex-col items-center gap-4 justify-start shadow-none pb-0 lg:h-[522px]"
            >
              <div className="w-full mb-auto h-3/12">
                <img
                  src={HeroExploreSvg}
                  alt={HeroExploreSvg}
                  className="w-full h-full"
                />
              </div>

              <div className="w-full mt-auto justify-center lg:h-9/12 lg:px-20">
                <div className="w-full h-full">
                  <img
                    src={HeroScreenPhone}
                    alt={HeroScreenPhone}
                    className="w-full h-full object-contain"
                  />
                </div>
              </div>

            </Card>

            <div className="w-full flex flex-col items-baseline justify-start gap-8 mb-10">
              <Typography
                variant="h2"
                className="font-clash text-start font-semibold tracking-two text-[#080912] text-3xl leading-[100%] lg:text-[40px] lg:leading-[40px]"
              >
                About Us
              </Typography>

              <Typography variant="h2" className="font-clash text-start font-medium tracking-two text-[#53557A] text-base leading-[168%] lg:text-2xl lg:max-w-[667px] lg:leading-[40.32px]">
                OrnaMap is a mobile app transforming transit navigation in Nigeria by providing real-time access to train, bus and ferry schedules, fare information, route suggestions, and navigation for key services like the Lagos Blue Line Metro, Red Line Train, Lagos Ferry Service, and Lagos-Ibadan Train. Since January 2021, OrnaMap has simplified travel planning, empowering thousands to make informed, confident journey decisions.
                <br/>
                <br/>
                <br/>
                With the added convenience of Cowry Card top-ups directly in the app, users can enjoy seamless, cashless commutes across trains, ferries, and buses. OrnaMap is committed to making travel easy, accessible, and reliable for all.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AboutUsHero;
