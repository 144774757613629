import {Fragment} from 'react';
import {Typography} from '@material-tailwind/react';
import HeroTextBackground from '../../assets/svg/faq/HeroImage.svg';

const Hero = () => {
  return (
    <Fragment>
      <div className="w-full mx-auto flex justify-center mb-24 px-4 lg:px-0">
        <div className="max-w-[1040px] w-full h-[120px] flex items-center justify-center rounded-[30px] overflow-hidden lg:h-[334px]"
             style={{
               backgroundImage: `url(${HeroTextBackground})`,
               backgroundRepeat: 'no-repeat',
               backgroundSize: 'cover',
             }}
        />
      </div>
    </Fragment>
  );
};

export default Hero;