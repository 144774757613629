import React, {Fragment} from 'react';
import Text from '../global/Text';
import BgGetStarted from '../../assets/img/homepage/bg-get-started-video.png';

const GetStated = () => {
  return (
    <Fragment>
      <div className="max-w-screen-xl px-2 items-center justify-center w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="flex items-center justify-center rounded-[30px] bg-[#ECECF0] p-6" style={{
          backgroundImage: `url(${BgGetStarted})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#ECECF0',
          clipPath: 'inset(1px 1px)'
        }}>
          <video autoPlay loop muted playsInline controls={false} className={'h-[368px] w-[180px] lg:h-[550px] lg:w-[277px]'}>
            <source src="https://res.cloudinary.com/ornamap/video/upload/v1691328125/ornamap-web-landing-video-webm.webm" type="video/webm" />
            <source src="https://res.cloudinary.com/ornamap/video/upload/v1691328609/ornamap-web-landing-video-mp4.mp4" type="video/mp4" />
            Sorry, your browser doesn{'\''}t support videos.
          </video>
        </div>

        <div className="hidden flex-col items-center h-full w-full p-10 justify-center lg:flex">
          <Text text={'How to get started'} size="2xl" weight={800} className="large-text-typography"/>

          <div className="flex flex-col w-full items-center justify-center">
            <ul className="max-w-md space-y-1 text-sm list-disc text-[#53557A] font-nunito get-started-list">
              <li>
                Enter your departure and destination locations, Choose from the recommended routes, including train and ferry options.
              </li>

              <li >
                Follow the provided directions for walking or driving to reach the train station or ferry harbour station.
              </li>

              <li>
                See stops, harbour point or station to get off.
              </li>

              <li>
                Finally, follow the walking or driving directions from the station to your final destination.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GetStated;