import {Fragment, useEffect, useRef, useState} from 'react';
import {Button, Typography} from '@material-tailwind/react';
import FaqItem from '../home/FagItem';

const AboutOrnaMap = () => {
  const stickyElementRef:any = useRef(null);

  const [openItems, setOpenItems] = useState(new Set(['1']));
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const stickyElement = stickyElementRef.current;

    const sentinel = document.createElement('div');
    sentinel.style.position = 'absolute';
    sentinel.style.top = '0';
    sentinel.style.height = '1px';
    sentinel.style.width = '1px';
    sentinel.style.opacity = '0';

    stickyElement?.parentNode.insertBefore(sentinel, stickyElement);

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(!entry.isIntersecting);
      },
      {
        threshold: [1],
      }
    );

    observer.observe(sentinel);

    return () => {
      observer.disconnect();
      sentinel.remove();
    };
  }, []);

  const faqItems = [
    {
      id: '1',
      question: 'What is OrnaMap?',
      answer: 'OrnaMap is a mobile app designed to streamline transit navigation across Nigeria, providing real-time information for trains, ferries, and buses. With features like schedule search, fare details, route suggestions, and navigation, OrnaMap helps users make informed travel decisions and plan their journeys with ease.'
    },
    {
      id: '2',
      question: 'Which transit services does OrnaMap support?',
      answer: 'OrnaMap currently supports the Lagos Blue Line Metro, Red-Line Train, Lagos Ferry Service BRT buses and the Lagos-Ibadan Train Service. We’re continuously expanding our network to provide comprehensive transit options across Nigeria.'
    },
    {
      id: '3',
      question: 'How does the Cowry Card funding feature work?',
      answer: 'OrnaMap offers an easy way to top up your Cowry Card balance directly within the app. By linking to your preferred payment method, you can add funds anytime, ensuring a cashless, hassle-free experience across supported trains, ferries, and buses.'
    },
    {
      id: '4',
      question: 'How do I top up my Cowry Card through OrnaMap?',
      answer: 'To top up your Cowry Card using OrnaMap, simply open the app and navigate to the "Fund Cowry Card" section. Select your desired top-up amount, link your payment method (such as a debit or credit card), and confirm the transaction. The funds will be instantly added to your Cowry Card balance for use across supported services.'
    },
    {
      id: '5',
      question: 'Are there any fees associated with topping up my Cowry Card?',
      answer: 'There may be low processing fees for topping up your Cowry Card, depending on the payment method and the amount. These fees will be clearly displayed before you confirm your top-up.'
    },
    {
      id: '6',
      question: 'Can I use any payment method to fund my Cowry Card?',
      answer: 'Currently, OrnaMap supports various payment methods, including debit and credit cards linked to local Nigerian banks. You can securely fund your Cowry Card through these options within the app.'
    },
    {
      id: '7',
      question: 'How do I make use of my cashback bonus?',
      answer: 'You get cashback bonuses on every third recharge of your Cowry wallet. And you can make use of it by simply turning on the toggle that says “use cash back bonus” on your next recharge.'
    },
    {
      id: '8',
      question: 'What should I do if my Cowry Card top-up doesn\'t go through?',
      answer: 'If your Cowry Card top-up fails, please check your payment details to ensure they are correct. You can also check for any service disruptions with your payment provider. If the issue persists, contact OrnaMap customer support through the app or email at support@ornamap.com for assistance.'
    },
    {
      id: '9',
      question: 'How long does it take for the funds to appear on my Cowry Card?',
      answer: 'Funds added to your Cowry Card through the OrnaMap app are typically credited instantly. However, in rare cases, it may take a few minutes for the transaction to be processed, depending on your payment method and network conditions.'
    },
    {
      id: '10',
      question: 'Is OrnaMap available outside Lagos?',
      answer: 'OrnaMap is focused on providing transit support across Nigeria, with services currently available in Lagos and select routes like the Lagos-Ibadan Train Service. We are actively working to expand coverage to more regions and cities.'
    },
    {
      id: '11',
      question: 'How do I get real-time schedule updates?',
      answer: 'OrnaMap provides live schedule updates directly from transit authorities, ensuring users have access to the latest information. To view real-time schedules, simply open the app, select your route, and get the most current transit details.'
    },
    {
      id: '12',
      question: 'Are there any fees for using OrnaMap?',
      answer: 'The OrnaMap app is free to download and use. Some services, such as Cowry Card funding, may include minimal transaction fees as specified by our payment partners.'
    },
    {
      id: '13',
      question: 'Does OrnaMap work offline?',
      answer: 'OrnaMap requires an internet connection to provide real-time updates and booking features. However, once you’ve planned a trip, some navigation features may work offline to guide you to your destination.'
    },
    {
      id: '14',
      question: 'How do I find the best route to my destination?',
      answer: 'With OrnaMap, you can view suggested routes and transport options based on your starting point and destination. The app provides recommended routes to help you reach your destination efficiently, whether by train, ferry, or other available options.'
    },
    {
      id: '15',
      question: 'How can I contact OrnaMap support?',
      answer: 'For any questions, concerns, or feedback, please reach out to our customer support team through the “Contact Us” feature in the app, or email us at support@ornamap.com'
    },
    {
      id: '16',
      question: 'Is my payment information secure on OrnaMap?',
      answer: 'Yes, OrnaMap uses secure payment gateways and encryption to protect all transactions and personal data. Your security and privacy are our top priority.'
    },
    {
      id: '17',
      question: 'Will OrnaMap expand to include other transportation services?',
      answer: 'We are committed to broadening our coverage and regularly evaluate opportunities to add new transit services, regions, and features to make OrnaMap a comprehensive travel companion for all users.'
    },
    {
      id: '18',
      question: 'Why did my cowry card recharge fail?',
      answer: 'Your cowry card recharge can fail because the amount you transferred did not match the amount you specified during the transaction. Our system requires the transferred amount to match exactly with the indicated recharge amount.'
    },
    {
      id: '19',
      question: 'What happens to the money I transferred?',
      answer: 'If your recharge fails due to a mismatched amount, a refund is automatically initiated.'
    },
    {
      id: '20',
      question: 'How long does it take to receive my refund?',
      answer: 'Refunds typically take 5-7 working days to process.'
    },
    {
      id: '21',
      question: 'Can I contact support if I have questions about my refund?',
      answer: 'Yes, our support team is happy to assist. You can reach us at support@ornamap.com or on WhatsApp at 0707 239 6527.'
    },
    {
      id: '22',
      question: 'What if I don’t receive my refund after 7 working days?',
      answer: 'If your refund hasn’t been received after 7 working days, please contact our support team for further assistance.'
    }
  ];

  const toggleItem = (itemId: string) => {
    setOpenItems(prev => {
      if (prev.has(itemId)) {
        return new Set();
      }
      return new Set([itemId]);
    });
  };

  return (
    <Fragment>
      <div className="w-full min-h-[450px] flex justify-center px-4 lg:px-0 lg:py-16">
        <div className="w-full max-w-[1240px] flex items-start flex-col gap-4 justify-start lg:gap-16 lg:flex-row lg:justify-center lg:items-center">
          <div className="bg-[#EAF4ED] px-5 py-3 rounded-full h-[46px] w-fit lg:hidden">
            <Typography variant="small" className="font-clash font-normal text-base text-[#080912] leading-[100%] tracking-two lg:leading-[22.14px]">
              Got Questions?
            </Typography>
          </div>

          <div className="w-full h-fit lg:h-full lg:w-1/3">
            <div className="flex flex-col justify-start max-w-[370px] gap-4 lg:sticky lg:top-0 lg:mt-8" ref={stickyElementRef}>
              <Typography
                variant="h2"
                className={`text-[#080912] font-clash font-semibold text-3xl leading-[100%] tracking-two max-w-[295px] transition-all duration-300 lg:text-[40px] lg:leading-[40px] ${
                  isSticky ? 'lg:mt-[100px] transform translate-y-0' : ''
                }`}
              >
                Some of the things you may want to know
              </Typography>

              <Typography
                className={'font-clash font-normal text-base leading-[100%] tracking-two max-w-[295px] text-[#53557A] transition-opacity duration-300 lg:mb-[32px] lg:text-2xl lg:leading-[29.52px]'}
              >
                We’ve answered them so you don’t have to ask.
              </Typography>
            </div>
          </div>

          <div className="w-full h-full lg:w-2/3">
            {/*<div className={`divide-[#00000033] divide-y`}>*/}
            <div className="w-full">
              {faqItems.map((item, index) => (
                <FaqItem
                  key={item.id}
                  question={item.question}
                  answer={item.answer}
                  index={index}
                  // Close others, Only open the one where the id matches the id of the item
                  isOpen={openItems.has(item.id)}
                  onToggle={() => toggleItem(item.id)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AboutOrnaMap;
