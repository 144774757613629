import React from 'react';
import Text from '../../global/Text';
import TrainWithRoute from '../../../assets/img/homepage/img-ferry-routes.svg';

const FerryArrival = () => {
  return (
    <React.Fragment>
      <div className="max-w-screen-xl px-2 w-full px-2">
        <div className={'flex items-center justify-center bg-[#282B59] rounded-[15px] w-full px-5 lg:p-10'}>
          <div className={'w-full grid grid-cols-2 gap-8 my-10 lg:my-20'}>
            <div className={'flex flex-col col-span-2 items-start justify-center w-full lg:col-span-1'}>
              <Text
                size={'3xl'}
                weight={800}
                text={'Coming Soon: Get Ferry Arrival and Departure Times with OrnaMap App! Stay Tuned'}
                className={'text-[#EAEAEE] leading-[41px] mb-4 hidden lg:flex'}
              />

              <Text
                weight={800}
                text={'Coming Soon: Get Ferry Arrival and Departure Times with OrnaMap App! Stay Tuned'}
                className={'!text-[#EAEAEE] leading-[33px] text-center mb-4 !text-[24px] flex lg:hidden'}
              />

              <Text
                weight={400}
                text={'Get ready for a seamless travel experience with the upcoming addition of ferry arrival and departure times to the OrnaMap app. Stay tuned for more updates!'}
                className={'text-[#EAEAEE] my-4 !text-[16px] text-center leading-[22px] lg:!text-lg lg:leading-[25px] lg:text-justify'}
              />

              <Text
                weight={800}
                text={'Stay Tune with OrnaMap App!'}
                className={'w-full text-[#EAEAEE] leading-[33px] !text-[24px] text-center my-4 lg:text-left lg:!text-2xl lg:leading-[25px]'}
              />
            </div>

            <div className={'hidden lg:flex'}>
              <img src={TrainWithRoute} alt={'Train with route'}/>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FerryArrival;